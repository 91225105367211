import React, { useEffect } from 'react';
import { HvGrid, HvTypography } from '@hitachivantara/uikit-react-core';
import { HvVizProvider } from '@hitachivantara/uikit-react-viz';
import QualityByDefect from './QualityByDefect';
import QualityByTrending from './QualityByTrending';
import { useDispatch } from 'react-redux';
import { getUserPreference } from '../actions';
import useToken from '../useToken';

function QualityAnalytics() {
	const { token } = useToken();
	const uid = token.username;
	const dispatch = useDispatch();
	const dispatchToGetUserPreference = (uid) => {
		dispatch(
			getUserPreference({
				uid: uid
			})
		);
	};

	useEffect(() => {
		dispatchToGetUserPreference(uid);
	}, [uid]);

	return (
		<HvVizProvider>
			<HvTypography variant="title2" >
				Quality KPIs
			</HvTypography>
			<div>
				<HvGrid container direction="column">
					<HvGrid item md={12}>
						<HvGrid container>
							<HvGrid item md={6}>
								<QualityByTrending uid={uid}/>
							</HvGrid>
							<HvGrid item md={6}>
								<QualityByDefect uid={uid}/>
							</HvGrid>
						</HvGrid>
					</HvGrid>
				</HvGrid>
			</div>
			
		</HvVizProvider>
	);
}

export default QualityAnalytics;


