import { all } from '@redux-saga/core/effects';
import { takeLatestWrapper } from './index';
import * as api from './../api/TankMaster';

export default function* sagas() {
	yield all([takeLatestWrapper('FETCH_TANK_SEARCH_RESULTS', api.getTankSearchResultsApi),
		takeLatestWrapper('FETCH_ALL_MACHINE_DATA', api.getAllMachinesData),
		takeLatestWrapper('FETCH_MACHINE_CHART_DATA', api.getMachinesChartData),
		takeLatestWrapper('FETCH_DROPDOWN_CHARTDATA', api.getTable9ChartDropdownData),
		takeLatestWrapper('FETCH_MACHINE_ALERT_DATA', api.getMachinesAlertData)
	]);
}
