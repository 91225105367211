import React, { useEffect, useState } from 'react';
import {useSelector } from 'react-redux';
import LineBarChart from '../shared/components/Charts/LineBarChart';
import { HvTypography } from '@hitachivantara/uikit-react-core';
import DatePicker from '../shared/components/DatePicker/DatePicker';
import PropTypes from 'prop-types';
import { getDowntimeReasonDetailsApi } from '../api/machine';
import { isEmpty } from '../shared/components/Util';
import { useLocation } from 'react-router-dom';

function DownTimeReason({ uid }) {
	DownTimeReason.propTypes = {
		uid: PropTypes.string.isRequired
	};
	const title = 'Downtime Reason';
	const [data, setData] = useState([]);
	const location = useLocation();
	const windowTimestamps = useSelector((state) => state.dashboard.windowTimestamps);
	const featureEnabled = useSelector((state) => state.dashboard.featureEnabled);
	const [loading,setLoading]= useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [toggledOffData, setToggledOffData] = useState([]);

	const handleOptionClickToggledOff = (params) => {
		setToggledOffData({ ...params });
	};

	const dispatchToGetDowntimeReason = (wts,thingId) => {
		getDowntimeReasonDetailsApi({
			thingId: thingId,
			endTimestamp: wts.end_datetime_ep,
			startTimestamp: wts.start_datetime_ep
		}).then((res) => {
			if(res[0]?.resultSet.length !== 0){
				setData(res[0]?.resultSet);
				setErrorMsg('');
			}else{
				setData(res[0]?.resultSet);
				setErrorMsg('');
			}
			setLoading(false);
		}).catch((err) => {
			setErrorMsg(err);
			setLoading(false);
		});
	};

	useEffect(() => {
		setLoading(true);
		if (!isEmpty(windowTimestamps) && windowTimestamps.length > 0 && isEmpty(toggledOffData)) {
			dispatchToGetDowntimeReason(windowTimestamps[0], location.state?.hierarchyDetails?.thingId);
		} else if (!isEmpty(toggledOffData) && !isEmpty(toggledOffData.wts)) {
			dispatchToGetDowntimeReason(toggledOffData.wts[0], location.state?.hierarchyDetails?.thingId);
		}
	}, [windowTimestamps, location, toggledOffData]);

	let labels = [];
	//let lineData = [];
	let barData = [];

	let sortedDescending = data.sort((a, b) => {
		return b.count - a.count;
	});

	sortedDescending.map((item) => {
		if (item) {
			labels.push(item.label);
			barData.push(item.count); //count=Hours
			//lineData.push(item.thing_status_reason_code_id);
		}
	});
	const canvas = document.createElement('canvas');
	
	let labelsWithLineBreaks = labels.map((item) => {
		const words = item.split(' '); 

		if (words.length > 1) {
			const mid = Math.round(words.length/2);
			return [words.slice(0,mid).join(' '),words.slice(mid).join(' ')];
		}else {
			return item; 
		}
	});

	const chartData = {
		labels : labelsWithLineBreaks,
		datasets: [
			// {
			// 	label: data[0] ? data[0].yRightLabel : '',
			// 	type: 'line',
			// 	data: lineData,
			// 	borderColor: 'rgb(255, 99, 132)',
			// 	backgroundColor: 'rgba(255, 99, 132, 0.5)',
			// 	yAxisID: 'rightYaxis',
			// 	categoryPercentage: '10'
			// },
			{
				label: data[0] ? data[0].yLeftLabel : '',
				data: barData,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: getbarcolor(canvas), //'rgba(53, 162, 235, 0.5)',
				//fillColor: ['rgba(0,10,220,0.5)','rgba(220,0,10,0.5)','rgba(220,0,0,0.5)','rgba(120,250,120,0.5)' ],
				yAxisID: 'leftYaxis',
				barThickness: '18',
				categoryPercentage: '10'
			}
		]
	};

	return (
		<>
			<HvTypography variant="title3" className='titleBox' style={{ height: '358px'}} id='downtime'>
				<div style={{ display: 'flex', height:'53px',paddingBottom:'65px' }}>
					<div>{title}</div>
					<div style={{ marginLeft: 'auto' }}>
						<DatePicker featureEnabled={featureEnabled} uid={uid} thingId={location?.state?.hierarchyDetails?.thingId} handleOptionClickToggledOff={handleOptionClickToggledOff}/>
					</div>
				</div>
				<LineBarChart id="barchart" data={chartData} options={options} loading={loading} errorMsg={errorMsg}/>
			</HvTypography>
		</>
	);
}

function getbarcolor(canvas) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 240);
	gradient.addColorStop(0, '#00172D');
	gradient.addColorStop(1, '#0052A2');
	return gradient;
}
const options = {
	responsive: true,
	interaction: {
		mode: 'index',
		intersect: true
	},
	stacked: true,
	plugins: {
		legend: {
			display: true,
			labels: {
				boxWidth: 10,
				boxHeight: 2
			}
		},
		datalabels: {
			display: false
		},
		title: {
			display: false,
			text: 'Downtime Reason'
		},
		tooltip: {
			cornerRadius: 4,
			backgroundColor: '#FFFFFF',
			titleColor: 'black',
			titleFont: {
				family: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
				weight: 10,
				size: 10,
				style: 'normal'
			},
			xAlign: 'left',
			yAlign: 'top',
			width: '100px',
			height: '25px',
			bodyColor: 'black',
			bodyFont: { size: 8 },
			boxWidth: 14,
			boxHeight: 1,
			callbacks:{
				title: (context) =>{
					return context[0].label.replaceAll(',',' ');
				}

			}
		}
	},
	scales: {
		// rightYaxis: {
		// 	type: 'linear',
		// 	display: true,
		// 	position: 'right'
		// },
		leftYaxis: {
			type: 'linear',
			display: true,
			position: 'left',
			grid: {
				drawOnChartArea: true
			},
			title: {
				display: true,
				text: 'Hrs',
				//color: '#191',
				font: {
					//family: 'Times',
					//size: 20,
					style: 'normal'
					//lineHeight: 1.2
				},
				padding: { top: 5, left: 0, right: 0, bottom: 0 }
			}
		},
		x: {
			grid: {
				display: false
			},
			ticks: {
				maxRotation: 0,
				minRotation: 0,
				autoSkip: false
			}
		}
	}
};
export default DownTimeReason;

