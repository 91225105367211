import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateAlertCount } from '../../actions';
import { isEmpty, convertToStartEpoch, convertToEndEpoch } from '../components/Util';
import { getAlertTableData, getDashboardAlertsData } from '../../api/line';

function AlertCount() {
	const dispatch = useDispatch();
	const windowTimestamps = useSelector((state) => state.dashboard.windowTimestamps);
	const location=useLocation();
	const userPreference= useSelector((state) => state.dashboard.userPreference);
	const adminCompany = useSelector((state) => state.dashboard.adminCompany);

	const dispatchToGetAlertTabelData = (wts,thingId) => {
		getAlertTableData({
			org_id: 1,
			group_id: 1,
			event_cid: thingId,
			event_type: '',
			severity_level: [''],
			asset_type: '',
			event_status: '',
			event_category: '',
			startDate: wts.start_datetime_ep,
			endDate: wts.end_datetime_ep
		}).then((res) => {
			if (res[0]?.resultSet.length !== 0) {
				updateAlerts(res[0]?.resultSet[0].total_rows);
			}else{
				updateAlerts(0);
			}
		}).catch(() => {
			updateAlerts(0);
		});
	};

	const updateAlerts = (count) => {
		dispatch(
			updateAlertCount(count)
		);
	};

	const getDashboardAlerts = (params) => {
		getDashboardAlertsData(params).then((res) => {
			if (res[0]?.resultSet.length !== 0) {
				updateAlerts(res[0]?.resultSet[0].total_rows);
			}else{
				updateAlerts(0);
			}
		}).catch(() => {
			updateAlerts(0);
		});
	};

	

	useEffect(()=>{
		if(!isEmpty(windowTimestamps) && !isEmpty(windowTimestamps[0])){
			if(!isEmpty(location.state?.hierarchyDetails?.thingId)){
				dispatchToGetAlertTabelData(windowTimestamps[0],location.state?.hierarchyDetails?.thingId);
			}
		}else if(Object.keys(adminCompany).length !== 0 && !isEmpty(userPreference) && !isEmpty(userPreference.preferredTimeperiod)&& isEmpty(location.state?.hierarchyDetails?.thingId)){
			if (userPreference?.preferredTimeperiod?.type === 'interval') {
				getDashboardAlerts({ mode: userPreference?.preferredTimeperiod?.value === 'Today' ? 'Day' : userPreference?.preferredTimeperiod?.value, hour_mode: 1, thingId: adminCompany[0].company_id, currentDate: Date.now(), startDate: 0 , endDate: 0 });
			} else if (userPreference?.preferredTimeperiod?.type == 'range') {
				const rawValue = userPreference?.preferredTimeperiod?.value;
				if (rawValue.includes(',')) {
					const [startDate, endDate] = rawValue.replace('[', '').replace(']', '').split(',');
					const formattedStartDate = new Date(startDate).toLocaleDateString();
					const formattedEndDate = new Date(endDate).toLocaleDateString();
					getDashboardAlerts({ mode: 'Custom', hour_mode: 1, thingId: adminCompany[0].company_id, currentDate: Date.now(), startDate: convertToStartEpoch(`${formattedStartDate} - ${formattedEndDate}`), endDate: convertToEndEpoch(`${formattedStartDate} - ${formattedEndDate}`)});
				}
			}
		}else{
			updateAlerts(0);
		}
	},[windowTimestamps,location,adminCompany,userPreference]);	


	return (
		<>
		</>
	);
}


export default AlertCount;

