import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 0
	};

	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

export const getQualityKPI = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getTrendingDetails',
			params: {
				productId: params.productId,
				defectId: params.defectId,
				thingId: params.thingId,
				endTime: params.endTime,
				startTime: params.startTime,
				mode: params.mode
			},
			ascOrder: false,
			cursor: 0,
			pageSize: 0
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getQualityDefect = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getSliceConfigurationParameters',
			requestBody: {
				queryParams: {
					productId: params.productId,
					flowId: params.flowId
				},
				sliceId: params.sliceId,
				thingId: params.thingId,
				timestampRequest: {
					endTimestamp: params.endTimestamp,
					startTimestamp: params.startTimestamp
				}
			},
			method: 'POST',
			requestParams: '/dashboard/api/v2/sliceconfig/',
			header: {
				uid: 1212
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getQualityDefectForAllProduct = async (paramData) => {
	const data = [];
	paramData.forEach((item) => {
		const reqItem = {
			apiName: 'midataapp.getSliceConfigurationParameters',
			requestBody: {
				queryParams: {
					productId: item.productId,
					flowId: item.flowId
				},
				sliceId: item.sliceId,
				thingId: item.thingId,
				timestampRequest: {
					endTimestamp: item.endTimestamp,
					startTimestamp: item.startTimestamp
				}
			},
			method: 'POST',
			requestParams: '/dashboard/api/v2/sliceconfig/',
			header: {
				uid: 1212
			}
		};
		data.push(reqItem);		
	});

	const response = await callMapicsApi('post', data);
	return response;
};


export const getQualityProdDropdown = async (params) => {
	const data = [
		{
			apiName: 'midataapp.fetchProductByThingId',
			params: {
				thingId: params.thingId
			},
			header: {
				uid: 1212
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};