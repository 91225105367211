import { useState } from 'react';

export default function useToken() {
	const getToken = () => {
		const tokenString = localStorage.getItem('userDetails');
		if (!tokenString) {
			return '';
		}
		const userDetails = JSON.parse(tokenString);
		return userDetails;
	};

	const [token, setToken] = useState(getToken());

	const saveToken = (user) => {
		const userDetails = user;
		// userDetails.endpoint = user.endpoint;
		localStorage.setItem('userDetails', JSON.stringify(userDetails));
		setToken(userDetails);
	};

	const clearToken = () => {
		localStorage.removeItem('userDetails');
	};

	const getUserName = () => {
		const tokenString = localStorage.getItem('userDetails');
		const userDetails = JSON.parse(tokenString);
		return userDetails?.name;
	};

	return {
		setToken: saveToken,
		clearToken,
		getUserName,
		token
	};
}
