import React, { useEffect, useState } from 'react';
import { HvGrid } from '@hitachivantara/uikit-react-core';
import PropTypes from 'prop-types';
import AssetView from './AssetView';
import AssetKpis from './AssetKpis';
import { isEmpty } from '../shared/components/Util';
import { getDeviceDetails } from '../api/machine';

function Asset({imageId, thingId, startDate, endDate}) {
	Asset.propTypes = {
		imageId: PropTypes.string.isRequired,
		thingId: PropTypes.string.isRequired,
		startDate: PropTypes.number.isRequired,
		endDate: PropTypes.number.isRequired
	};
	let [data, setData] = useState([]);
	const [errorMsg, setErrorMsg] = useState('');
	const [loading, setLoading] = useState(false);
	
	const dispatchToGetDeviceDetails = (thingId) => {
		getDeviceDetails({
			thingId: thingId,
			startDate: startDate,
			endDate: endDate
		}).then((res) => {
			if(res[0]?.resultSet.length !== 0){
				setData(res[0]?.resultSet);
				setErrorMsg('');
			}else{
				setData(res[0]?.resultSet);
				setErrorMsg('');
			}
			setLoading(false);
		}).catch((err) => {
			setErrorMsg(err);
			setLoading(false);
		});
	};

	useEffect(() =>{
		setLoading(true);
		if(!isEmpty(thingId)){
			dispatchToGetDeviceDetails(thingId);
		}else{
			setLoading(false);
			setErrorMsg('error in app');
		}
	}, [thingId]);

	return (
		<>
			<HvGrid container spacing={5} >
				<HvGrid item md={6} style={{height: '490px'}} >
					<AssetView imageId={imageId} data={data} />
				</HvGrid>
				<HvGrid item md={6} style={{ height: '490px', paddingLeft:'20px'}}>
					<AssetKpis loading={loading} errorMsg={errorMsg} />
				</HvGrid>
			</HvGrid>
		</>
	);
}
export default Asset;
