import { LogLevel } from '@azure/msal-browser';
export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_SSO_ClientID, // This is the ONLY mandatory field that you need to supply.
		authority: process.env.REACT_APP_SSO_Authority, //'https://login.microsoftonline.com/e3986f93-0b0e-4dfd-956c-481e10f317c5', // Defaults to "https://login.microsoftonline.com/common"
		redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
		postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
		navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
				case LogLevel.Error:
					// Show Logger Message here;
					return;
				case LogLevel.Info:					
					return;
				case LogLevel.Verbose:
					return;
				case LogLevel.Warning:
					return;
				default:
					return;
				}
			}
		}
	}
};
export const loginRequest = {
	scopes: []
};
export const silentRequest = {
	scopes: ['openid', 'profile'],
	loginHint: 'example@domain.net'
};
