import { all } from '@redux-saga/core/effects';
import { takeLatestWrapper } from './index';
import * as api from './../api/dashboard';

export default function* sagas() {
	yield all([
		takeLatestWrapper('FETCH_FACTORY_DISTRIBUTION_MAP', api.getFactoryDistributionMap),
		takeLatestWrapper('FETCH_HIERARCHY_BY_ID', api.fetchHierarchyById),
		takeLatestWrapper('FETCH_OEE_VALUES', api.getOEEValues),
		takeLatestWrapper('FETCH_USER_PREFERENCE', api.getUserPreference),
		takeLatestWrapper('UPDATE_USER_PREFERENCE', api.updateUserPreference),
		takeLatestWrapper('FETCH_WINDOW_TIMESTAMP', api.getWindowTimeStamps),
		takeLatestWrapper('GET_ADMIN_COMPANY', api.getAdminCompany),
		takeLatestWrapper('GET_FACTORY_PRODUCT_BY_ID', api.fetchProductByThingId),
		takeLatestWrapper('GET_PRODUCTION_VOLUME_DETAILS', api.fetchProductionVolumeDetails),
		takeLatestWrapper('GET_ENTERPRISE_PRODUCT_BY_ID', api.fetchEnterpriseProductByThingId),
		takeLatestWrapper('GET_PRODUCTION_VOLUME_BY_FACTORIES', api.fetchProductionVolumeByFactories),
		takeLatestWrapper('GET_PRODUCTION_VOLUME_BY_LINES', api.fetchProductionVolumeByLines)
	]);
}
