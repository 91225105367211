// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remaining-life-container {
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 300px; 
  }
  
  .label-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
  }
  
  .alert-icon {
    color: red;
    margin-left: 4px;
    cursor: pointer;
    font-size: 22px;
  }
  
  .progress-bar {
    position: relative;
    height: 20px;
    background: linear-gradient(to right, #FF4136 0%, #FF851B 25%, #FFDC00 50%, #2ECC40 75%, #2ECC40 100% );
    border-radius: 10px;
  }
  
  .progress {
    height: 100%;
    background: black;
    border-radius: 10px 0 0 10px;
  }
  
  .marker {
    position: absolute;
    top: 10px; 
    width: 20px;
    height: 30px;
    background: black;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
  
  .label-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }

  .days-remaining {
    font-size: 1.5em; 
    font-weight: bold;
    margin: 0 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/shared/components/RemainingLifeIndicator.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,gBAAgB;IAChB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,uGAAuG;IACvG,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,iBAAiB;IACjB,4BAA4B;EAC9B;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,8CAA8C;EAChD;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;EACf","sourcesContent":[".remaining-life-container {\r\n    font-family: Arial, sans-serif;\r\n    width: 100%;\r\n    max-width: 300px; \r\n  }\r\n  \r\n  .label-top {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin-bottom: 8px;\r\n    font-size: 15px;\r\n  }\r\n  \r\n  .alert-icon {\r\n    color: red;\r\n    margin-left: 4px;\r\n    cursor: pointer;\r\n    font-size: 22px;\r\n  }\r\n  \r\n  .progress-bar {\r\n    position: relative;\r\n    height: 20px;\r\n    background: linear-gradient(to right, #FF4136 0%, #FF851B 25%, #FFDC00 50%, #2ECC40 75%, #2ECC40 100% );\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  .progress {\r\n    height: 100%;\r\n    background: black;\r\n    border-radius: 10px 0 0 10px;\r\n  }\r\n  \r\n  .marker {\r\n    position: absolute;\r\n    top: 10px; \r\n    width: 20px;\r\n    height: 30px;\r\n    background: black;\r\n    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);\r\n  }\r\n  \r\n  .label-bottom {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-top: 4px;\r\n  }\r\n\r\n  .days-remaining {\r\n    font-size: 1.5em; \r\n    font-weight: bold;\r\n    margin: 0 4px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
