import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 114
	};

	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

//get event status dropdown list in Asset Analytics alert screen
export const getEventStatusList = async () => {
	const data = [
		{
			apiName: 'event_mgmt.getEventStatusByType',
			params: {
				'event_type':'Failure Prediction',
				'status_type':'E'
			},
			'sortBy': 1,
			'ascOrder': false,
			'cursor': 0,
			'pageSize': 1000
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};
//get Severity dropdown list in Asset Analytics alert screen
export const getSeverityList = async () => {
	const data = [
		{
			apiName: 'event_mgmt.getSeverity',
			params: {
				'group_id': 0,
				'is_active': null,
				'search': ''
			},
			'sortBy': 'severity_id',
			'ascOrder': true,
			'cursor': 0,
			'pageSize': 50
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//get alert details once click on Cell in Asset Analytics table to show alert info in alert screen
export const getEventConfigurationByThingId = async (params) => {
	const data = [
		{
			apiName: 'event_mgmt.getAllEventConfig_ConfigurationByConfigName',
			params: {
				'thing_id': params.thingId
			},
			'sortBy': '',
			'ascOrder': false,
			'cursor': 0,
			'pageSize': 5,
			'version' :1
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

//To create Alerts in Asset Analytics using alert screen
export const createAssetAnalyticsAlert = async (params) => {
	const data = [
		{
			apiName: 'event_g.getEventMSeq',
			method: 'GET',
			exeOrder: 1,
			'name': 'seq'
		},
		{
			apiName: 'event_g.addEventDetails',
			'params': { 
				'event_cid': params.event_cid,
				'config_name': params.config_name,
				'event_type_id': params.event_type_id,
				'event_type': params.event_type,
				'event_cat_id': params.event_cat_id,
				'event_category': params.event_category,
				'org_id': params.org_id,
				'group_id': params.group_id,
				'asset_type': params.asset_type,
				'event_name': params.event_name,
				'description': params.description,
				'properties': params.properties,
				'event_created_date': params.event_created_date,
				'event_status_id': params.event_status_id,
				'event_status': params.event_status,
				'is_auto_closed': params.is_auto_closed,
				'rule_id': params.rule_id,
				'display_name': params.display_name,
				'recommendations': params.recommendations,
				'icon_id': params.icon_id,
				'severity_level': params.severity_level,
				'severity_priority': params.severity_priority,
				'severity_color': params.severity_color,
				'parent_id': params.parent_id,
				'parameters': params.parameters,
				'event_id': {
					'seq': params.seq
				},
				'new_properties': params.new_properties
			},
			method: 'POST',
			exeOrder: 2,
			'dependOn': ['seq']
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};



