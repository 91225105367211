export function getDistributionMap(payload) {
	return { type: 'FETCH_FACTORY_DISTRIBUTION_MAP_REQUESTED', payload };
}
export function getQualityKPI(payload) {
	return { type: 'FETCH_QUALITY_KPI_REQUESTED', payload };
}
export function getQualityByDefect(payload) {
	return { type: 'FETCH_QUALITY_DEFECT_REQUESTED', payload };
}
export function getDowntimeReason(payload) {
	return { type: 'FETCH_MACHINE_DOWNTIME_REASON_REQUESTED', payload };
}
export function getOeeCalculationSummaryApi(payload) {
	return { type: 'FETCH_MACHINE_OEE_CALCULATION_REQUESTED', payload };
}
export function getImageById(payload) {
	return { type: 'FETCH_IMAGE_BYID_REQUESTED', payload };
}
export function getDeviceDetails (payload) {
	return { type: 'FETCH_DEVICE_DETAILS_REQUESTED', payload };
}
export function getTankSearchResultsApi(payload) {
	return { type: 'FETCH_TANK_SEARCH_RESULTS_REQUESTED', payload };
}
export function getOEEValues(payload) {
	return { type: 'FETCH_OEE_VALUES_REQUESTED', payload };
}
export function getQualityProdDropdown(payload) {
	return { type: 'FETCH_QUALITY_PROD_DROPDOWN_REQUESTED', payload };
}
export function fetchHierarchyById(payload) {
	return { type: 'FETCH_HIERARCHY_BY_ID_REQUESTED', payload};
}
export function updateSelectedHierarchy(payload){
	return { type: 'UPDATE_SELECTED_HIERARCHY', payload };
}
export function getSliceConfigurationParameters (payload) {
	return { type: 'FETCH_SLICE_CONFIGURATION_PARAMS_REQUESTED', payload };
}
export function getMachineAvailabilityInsights (payload) {
	return { type: 'FETCH_MACHINE_AVAILABILITY_INSIGHTS_REQUESTED', payload };
}
export function getDowntimeStatusApi(payload) {
	return { type: 'FETCH_DOWNTIME_STATUS_REQUESTED', payload };
}
export function getReasonCodesApi(payload) {
	return { type: 'FETCH_REASONCODES_REQUESTED', payload };
}
export function createDowntime(payload) {
	return { type: 'CREATE_DOWNTIME_REQUESTED', payload };
}
export function getDownTimeList(payload) {
	return { type: 'FETCH_DOWNTIME_LIST_REQUESTED', payload };
}
export function deleteDownTime(payload) {
	return { type: 'DELETE_DOWNTIME_REQUESTED', payload };
}
export function getAllMachinesData(payload) {
	return { type: 'FETCH_ALL_MACHINE_DATA_REQUESTED', payload };
}
export function getTable9ChartDropdownData(payload) {
	return { type: 'FETCH_DROPDOWN_CHARTDATA_REQUESTED', payload };
}
export function getMachinesChartData(payload) {
	return { type: 'FETCH_MACHINE_CHART_DATA_REQUESTED', payload };
}
export function getMachineAlertData(payload) {
	return { type: 'FETCH_MACHINE_ALERT_DATA_REQUESTED', payload };
}
export function getUserPreference(payload) {
	return { type: 'FETCH_USER_PREFERENCE_REQUESTED', payload };
}
export function updateUserPreference(payload) {
	return { type: 'UPDATE_USER_PREFERENCE_REQUESTED', payload };
}
export function getWindowTimestamps(payload) {
	return { type: 'FETCH_WINDOW_TIMESTAMP_REQUESTED', payload };
}
export function toggleFeature() {
	return { type: 'TOGGLE_FEATURE' };
}
export function getAdminCompany(){
	return { type: 'GET_ADMIN_COMPANY_REQUESTED'};
}
export function getSenValDropdownData(payload) {
	return { type: 'FETCH_SENSOR_VALUES_DROPDOWN_REQUESTED', payload };
}
export function getAssetTimeseries(payload) {
	return { type: 'FETCH_ASSET_TIME_SERIES_REQUESTED', payload };
}
export function getProductByThingId(payload) {
	return {type: 'GET_FACTORY_PRODUCT_BY_ID_REQUESTED', payload};
}
export function getEnterpriseProductByThingId(payload) {
	return {type: 'GET_ENTERPRISE_PRODUCT_BY_ID_REQUESTED', payload};
}
export function getProductionVolumneDetails(payload) {
	return {type: 'GET_PRODUCTION_VOLUME_DETAILS_REQUESTED', payload};
}
export function getProductionVolumeByFactories(payload) {
	return { type: 'GET_PRODUCTION_VOLUME_BY_FACTORIES_REQUESTED', payload };
}
export function getProductionVolumeByLines(payload) {
	return { type: 'GET_PRODUCTION_VOLUME_BY_LINES_REQUESTED', payload };
}
export function getSenWindowTimestamps(payload) {
	return { type: 'FETCH_SEN_WINDOW_TIMESTAMP_REQUESTED', payload };
}
export function getAlertCount(payload){
	return { type:'ALERT_COUNT_REQUESTED',payload};
}

export function getAlertTableData(payload){
	return{type:'ALERT_TABLE_DATA_REQUESTED',payload};
}

export function getDeviceHealthDetails(payload){
	return{type:'GET_DEVICE_HEALTH_DETAILS_REQUESTED',payload};
}

export function updateAlertCount(payload){
	return{type:'UPDATE_ALERT_COUNT',payload};
}

export function updateVerticalNavigation(payload){
	return{type:'UPDATE_VERTICAL_NAVIGATION',payload};
}

export function getEventStatusList(payload){
	return{type:'FETCH_EVENT_STATUS_LIST_REQUESTED',payload};
}

export function getSeverityList(payload){
	return{type:'FETCH_SEVERITY_LIST_REQUESTED',payload};
}

export function getEventConfigurationByThingId(payload){
	return{type:'FETCH_EVENT_CONFIGURATION_REQUESTED',payload};
}

export function createAssetAnalyticsAlert(payload){
	return{type:'CREATE_ASSET_ALERT_REQUESTED',payload};
}

export function updateTank(payload){
	return{type:'UPDATE_TANK',payload};
}
