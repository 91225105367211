import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getOeeCalculationSummaryApi } from '../api/machine';
import Loading from '../shared/Loading/Loading';
import { ERROR_MESSAGE, isEmpty } from '../shared/components/Util';

function Oee() {

	const location = useLocation();
	const [isLoading, setIsLoading] = useState(true);
	const [ oeeData , setOeeData] = useState({});
	const [showError, setShowError] = useState(false);
	const [message, setMessage] = useState('');
	const OperationalParametersParams = useSelector((state) => state.dashboard.OperationalParametersParams);

	const fetchOEEDetails = () => {
		getOeeCalculationSummaryApi({
			thingId: location.state?.hierarchyDetails?.thingId,
			filter_datetime: OperationalParametersParams.filter_datetime,
			mode: OperationalParametersParams.mode,
			hour_mode_duration: OperationalParametersParams.hour_mode_duration,
			filter_start: OperationalParametersParams.filter_start,
			filter_end: OperationalParametersParams.filter_end
		}).then((res) => {
			setIsLoading(false);
			if (res[0]?.resultSet.length !== 0) {
				setOeeData(res[0]?.resultSet[0]);
				setShowError(false);
				setMessage('');
			}
			else {
				setShowError(true);
				setMessage('No Data Found');
			}
		}).catch(() => {
			setIsLoading(false);
			setShowError(true);
			setMessage(ERROR_MESSAGE);
		});
	};

	useEffect(() => {
		setIsLoading(true);
		if(!isEmpty(OperationalParametersParams)){
			fetchOEEDetails();
		}
	}, [OperationalParametersParams,location]);

	const getColors = (col) =>{
		let retColor = { availability_color: '', performance_color: '', quality_color: '', oee_color: ''};
		
		if(col.availability_status != ''){
			if(col.availability_status.toLowerCase() === 'critical'){
				retColor.availability_color = 'rgb(255, 94, 108)';
			}
			else if(col.availability_status.toLowerCase() === 'warning'){
				retColor.availability_color = 'rgb(230, 140, 23)';
			}
			else{
				retColor.availability_color = 'rgb(99, 166, 33)';
			}			
		}		
		if(col.performance_status != ''){
			if(col.performance_status.toLowerCase() === 'critical'){
				retColor.performance_color = 'rgb(255, 94, 108)';
			}
			else if(col.performance_status.toLowerCase() === 'warning'){
				retColor.performance_color = 'rgb(230, 140, 23)';
			}
			else{
				retColor.performance_color = 'rgb(99, 166, 33)';
			}		
		}
		
		if(col.quality_status != ''){
			if(col.quality_status.toLowerCase() === 'critical'){
				retColor.quality_color = 'rgb(255, 94, 108)';
			}
			else if(col.quality_status.toLowerCase() === 'warning'){
				retColor.quality_color = 'rgb(230, 140, 23)';
			}
			else{
				retColor.quality_color = 'rgb(99, 166, 33)';
			}
		}
		
		if(col.OEE_status != ''){
			if(col.OEE_status.toLowerCase() === 'critical'){
				retColor.oee_color = 'rgb(255, 94, 108)';
			}
			else if(col.OEE_status.toLowerCase() === 'warning'){
				retColor.oee_color = 'rgb(230, 140, 23)';
			}
			else{
				retColor.oee_color = 'rgb(99, 166, 33)';
			}
		}
		return retColor;
	};

	let colors;
	if(!isEmpty(oeeData)){
		colors = getColors(oeeData);
	}
	
	return (
		<>
			{ isLoading && <Loading /> }
			{/* { !isLoading && showError && <div>{message}</div>} */}
			{ !isLoading && showError && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}> <span style={{fontWeight:'200', fontSize:'14px'}}> {message}</span></div>}
			{ !isLoading && !isEmpty(oeeData) &&
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white', width:'350px', marginLeft: 'auto', marginRight: 'auto'}}>
				<div className='circle' style={{backgroundColor: colors.oee_color}} >
					<div className='circleHeader'>OEE</div>
					<span  style={{ fontSize: '12px' }}>{oeeData.OEE}%</span>
					
				</div>
				<div className='circle' style={{backgroundColor: colors.availability_color}} >
					<div className='circleHeader'>A</div>
					<span  style={{ fontSize: '12px' }}>{oeeData.availability}%</span>
					
				</div>
				<div className='circle' style={{backgroundColor: colors.availability_color}} >
					<div className='circleHeader'>P</div>
					<span  style={{ fontSize: '12px' }}>{oeeData.performance}%</span>
					
				</div>
				<div className='circle' style={{backgroundColor: colors.quality_color}} >
					<div className='circleHeader'>Q</div>
					<span  style={{ fontSize: '12px' }}>{oeeData.quality}%</span>
					
				</div>
			</div>
			}
		</>
	);
}

export default Oee;
