// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .headerStyle {
    font-weight: bold;
    text-align: left;
    font-size: 24px;
    white-space: nowrap;
  }
  
  .datePickerStyle {
    flex: 0 0 200px;
    display: block;
    justify-content: flex-end;
    padding: 8px;
  }
  
  .tabsContainerStyle {
    display: flex;
    align-items: center;
    width: 100%; 
    position: relative;
    margin-bottom: 16px;
  }
  
  .rightforwardMessageStyle {
    position: absolute;
    background-color: white;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
    top: 35px;
    right: 0;
    transform: translateX(0);
    z-index: 2;
  }

  .leftforwardMessageStyle {
    position: absolute;
    background-color: white;
    padding: 8px;
    border: 1px solid gray;
    border-radius: 4px;
    top: 35px;
    right: 73%;
    transform: translateX(0);
    z-index: 2;
  }`, "",{"version":3,"sources":["webpack://./src/ma/Sensor/Sensor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,wBAAwB;IACxB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,wBAAwB;IACxB,UAAU;EACZ","sourcesContent":[".containerStyle {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    margin-bottom: 16px;\r\n  }\r\n  \r\n  .headerStyle {\r\n    font-weight: bold;\r\n    text-align: left;\r\n    font-size: 24px;\r\n    white-space: nowrap;\r\n  }\r\n  \r\n  .datePickerStyle {\r\n    flex: 0 0 200px;\r\n    display: block;\r\n    justify-content: flex-end;\r\n    padding: 8px;\r\n  }\r\n  \r\n  .tabsContainerStyle {\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%; \r\n    position: relative;\r\n    margin-bottom: 16px;\r\n  }\r\n  \r\n  .rightforwardMessageStyle {\r\n    position: absolute;\r\n    background-color: white;\r\n    padding: 8px;\r\n    border: 1px solid gray;\r\n    border-radius: 4px;\r\n    top: 35px;\r\n    right: 0;\r\n    transform: translateX(0);\r\n    z-index: 2;\r\n  }\r\n\r\n  .leftforwardMessageStyle {\r\n    position: absolute;\r\n    background-color: white;\r\n    padding: 8px;\r\n    border: 1px solid gray;\r\n    border-radius: 4px;\r\n    top: 35px;\r\n    right: 73%;\r\n    transform: translateX(0);\r\n    z-index: 2;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
