import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import Loading from '../../Loading/Loading';

function MultiLineChartComponent({data, CustomXAxisTick, loading, currentTab}) {
	MultiLineChartComponent.propTypes = {
		data: PropTypes.array.isRequired,
		CustomXAxisTick: PropTypes.array.isRequired,
		loading: PropTypes.bool.isRequired,
		currentTab: PropTypes.number.isRequired
	};

	const hasDefaultData = data.every((item) => item.sensorTimestamp === 0 || item.value === 0 || item.value === null);

	const [groupedData, setGroupedData] = useState({});

	useEffect(() => {
		const grouped = data.reduce((result, item) => {
			const { DeviceId } = item;
			if (!result[DeviceId]) {
				result[DeviceId] = [];
			}
			result[DeviceId].push(item);
			return result;
		}, {});
		setGroupedData(grouped);
	}, [data]);

	const deviceIds = Object.keys(groupedData);

	const numYAxes = deviceIds.length;

	const customColorScale = ['#72CCC0', '#5E89D8', '#FFD00A', '#A16CB4', '#6A864E', '#7E682D'];

	const [slicedDataByDeviceId, setSlicedDataByDeviceId] = useState({});

	const dataInterval = () => {
		if (currentTab===0 || currentTab===1) {
			return 15 * 1000;
		} else if (currentTab===2 || currentTab===3) {
			return 60 * 1000;
		} else if (currentTab===4) {
			return 10 * 60 * 1000;
		} 
	};

	useEffect(() => {
		const updatedSlicedData = {};
		const firstTimestamp = CustomXAxisTick[0];
		const lastTimestamp = CustomXAxisTick[CustomXAxisTick.length - 1];
		for (const DeviceId in groupedData) {
			const deviceData = groupedData[DeviceId];
			const extractedData = [];
			for (let i = firstTimestamp; i <= lastTimestamp; i += dataInterval()) {
				const timestamp = i;
				const entry = deviceData.find((item) => item.sensorTimestamp === i);
				if (entry) {
					extractedData.push(entry);
				} else {
					const dummyData = {
						'sensor_date': new Date(timestamp).toISOString(),
						'sensorTimestamp': i,
						'DeviceId': data[0]?.DeviceId,
						'SiteWiseAssetId': '',
						'KPI_Label': data[0]?.KPI_Label,
						'Lower_Critical_Threshold': data[0]?.Lower_Critical_Threshold,
						'Upper_Critical_Threshold': data[0]?.Upper_Critical_Threshold,
						'Lower_Warning_Threshold': data[0]?.Lower_Warning_Threshold,
						'Upper_Warning_Threshold': data[0]?.Upper_Warning_Threshold,
						'Unit_of_Measure': data[0]?.Unit_of_Measure,
						'Enable_KPI_monitoring': '',
						'Roundoff_Factor': ''
					};
					extractedData.push(dummyData);
				}
			}
			updatedSlicedData[DeviceId] = extractedData;
		}
		setSlicedDataByDeviceId(updatedSlicedData);
	}, [groupedData, CustomXAxisTick]);

	const yAxisComponents = [];
	for (let i = 0; i < numYAxes; i += 1) {
		const orientation = i < 3 ? 'left' : 'right';
		const yAxisId = `${orientation}${i + 1}`;
		const tickColor = customColorScale[i % customColorScale.length];
		yAxisComponents.push(
			<YAxis
				key={yAxisId}
				yAxisId={yAxisId}
				orientation={orientation}
				axisLine={{ display: 'none' }}
				tickLine={{ display: 'none' }}
				type='number'
				domain={[0, 'auto']}
				tick={{
					fill: tickColor,
					fontSize: 16,
					fontWeight: 'bold'
				}}
			/>
		);
	}

	const [hiddenLines, setHiddenLines] = useState({});

	const CustomLegend = () => {
		const toggleLineVisibility = (DeviceId) => {
			setHiddenLines((prevHiddenLines) => ({
				...prevHiddenLines,
				[DeviceId]: !prevHiddenLines[DeviceId]
			}));
		};

		const legendItems = [];

		for (const DeviceId in groupedData) {
			const deviceData = groupedData[DeviceId];

			if (deviceData.length > 0) {
				const { KPI_Label } = deviceData[0];
				const color = customColorScale[legendItems.length % customColorScale.length];

				legendItems.push(
					<div
						key={DeviceId}
						style={{display: 'flex',
							alignItems: 'center',
							marginRight: '20px',
							cursor: 'pointer',
							textDecoration: hiddenLines[DeviceId] ? 'line-through' : 'none'}}
						onClick={() => {
							toggleLineVisibility(DeviceId);
						}}
					>
						<div style={{ backgroundColor: color,
							width: '20px',
							height: '6px',
							marginRight: '5px' }} />
						<div>{KPI_Label}</div>
					</div>
				);
			}
		}

		return (
			<div style={{display: 'flex', alignItems: 'center'}}>
				{legendItems}
			</div>
		);
	};	

	const containerStyle = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '1300px'
	};

	const [allLegendsClicked, setAllLegendsClicked] = useState(false);

	useEffect(() => {
		const areAllClicked = deviceIds.every((DeviceId) => hiddenLines[DeviceId]);
		setAllLegendsClicked(areAllClicked);
	}, [hiddenLines, deviceIds]);

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const formatDate = (timestamp) => {
				const date = new Date(timestamp);
				const formattedDate = date.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric'
				});
				return formattedDate;
			};

			return (
				<div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
					<div style={{ color: 'black', marginBottom: '5px' }}>
						Time: {formatDate(label)}
					</div>
					{payload.map((dataItem, index) => (
						<div key={`tooltip-item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
							<div style={{ width: '20px', height: '6px', background: dataItem.color, marginRight: '5px' }}></div>
							<div style={{ color: 'black' }}>
								{dataItem.payload.KPI_Label}: {dataItem.value || 0} {dataItem.payload.Unit_of_Measure}
							</div>
						</div>
					))}
				</div>
			);
		}

		return null;
	};

	CustomTooltip.propTypes = {
		active: PropTypes.bool,
		payload: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.string,
				value: PropTypes.number,
				name: PropTypes.string
			})
		),
		label: PropTypes.string
	};	

	return (
		<div style={containerStyle}>
			{loading || data.length === 0 ? (
				<Loading />
			) : hasDefaultData ? (
				<div>
					<p>No Data Found</p>
				</div>
			) : (
				<>
					<div >
						<LineChart width={1300} height={400} data={slicedDataByDeviceId} margin={{ right: 30, bottom: 40 }}>
							<CartesianGrid strokeDasharray="3 3" />
							{allLegendsClicked ? null : (
								<XAxis
									dataKey="sensorTimestamp"
									type="category"
									allowDuplicatedCategory={false}
									ticks={CustomXAxisTick}
									interval={0}
									tickFormatter={(timestamp, index) => {
										const date = new Date(timestamp);
										const hours = date.getHours();
										const minutes = date.getMinutes();
										const ampm = hours >= 12 ? 'PM' : 'AM';
										const formattedHours = hours % 12 || 12;
										const formattedMinutes = minutes.toString().padStart(2, '0');
										if (index == 0) {
											const curTimestamp = CustomXAxisTick[index + 1];
											const timeDiff = curTimestamp - timestamp;
											if (timeDiff >= 23 * 3600 * 1000 && timeDiff <= 25 * 3600 * 1000) {
												const months = [
													'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
												];
												const month = months[date.getMonth()];
												const day = date.getDate();
												return `${month}-${day}`;
											}
										}else if (index > 0) {
											const prevTimestamp = CustomXAxisTick[index - 1];
											const timeDiff = timestamp - prevTimestamp;
											if (timeDiff >= 23 * 3600 * 1000 && timeDiff <= 25 * 3600 * 1000) {
												const months = [
													'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
												];
												const month = months[date.getMonth()];
												const day = date.getDate();
												return `${month}-${day}`;
											}
										}
										return `${formattedHours}:${formattedMinutes} ${ampm}`;
									}}
									tick={{ fontSize: 14 }}
									tickMargin={25} 
								/>							
							)} 
							{yAxisComponents.map((yaxisticks) => {
								return yaxisticks;
							})}
							<Tooltip content={<CustomTooltip />} />
							{deviceIds.slice(0, numYAxes).map((DeviceId, index) => (
								<Line
									key={DeviceId}
									type="monotone"
									dataKey="value"
									name={DeviceId}
									data={slicedDataByDeviceId[DeviceId]}
									yAxisId={yAxisComponents[index].props.yAxisId}
									stroke={customColorScale[index % customColorScale.length]}
									hide={hiddenLines[DeviceId]} 
									dot={false}
								/>
							))}
						</LineChart>
					</div>
					<CustomLegend />
				</>
			)}
		</div>
	);	
}

export default MultiLineChartComponent;

