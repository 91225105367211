// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HvBreadCrumb-root{
    height: 32px;
}

.HvPathElement-centerContainer a{
    font-weight: 400;
    font-size: 14px;
}

.HvPathElement-centerContainer p{
    font-size: 14px;
}

.HvPathElement-centerContainer a:hover{
    border-bottom: 1px solid var(--uikit-colors-base_dark);
}

.HvPathElement-centerContainer p:hover{
    border-bottom: 1px solid var(--uikit-colors-brand);
}

#custom-menu-dropdown-list .HvIsFocused{
    outline: none;
    box-shadow: none;  
    background-color: unset;  
}

.HvList-item:hover{
    border-bottom: 1px solid var(--uikit-colors-brand);
    background-color: var(--uikit-colors-backgroundColor) !important;  
}

.HvDropDownMenu-menuListRoot{
    max-height: 52vh;
    padding: var(--uikit-space-xs);
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Breadcrumb/Breadcrumb.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sDAAsD;AAC1D;;AAEA;IACI,kDAAkD;AACtD;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,kDAAkD;IAClD,gEAAgE;AACpE;;AAEA;IACI,gBAAgB;IAChB,8BAA8B;AAClC","sourcesContent":[".HvBreadCrumb-root{\r\n    height: 32px;\r\n}\r\n\r\n.HvPathElement-centerContainer a{\r\n    font-weight: 400;\r\n    font-size: 14px;\r\n}\r\n\r\n.HvPathElement-centerContainer p{\r\n    font-size: 14px;\r\n}\r\n\r\n.HvPathElement-centerContainer a:hover{\r\n    border-bottom: 1px solid var(--uikit-colors-base_dark);\r\n}\r\n\r\n.HvPathElement-centerContainer p:hover{\r\n    border-bottom: 1px solid var(--uikit-colors-brand);\r\n}\r\n\r\n#custom-menu-dropdown-list .HvIsFocused{\r\n    outline: none;\r\n    box-shadow: none;  \r\n    background-color: unset;  \r\n}\r\n\r\n.HvList-item:hover{\r\n    border-bottom: 1px solid var(--uikit-colors-brand);\r\n    background-color: var(--uikit-colors-backgroundColor) !important;  \r\n}\r\n\r\n.HvDropDownMenu-menuListRoot{\r\n    max-height: 52vh;\r\n    padding: var(--uikit-space-xs);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
