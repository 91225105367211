import React from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { ERROR_MESSAGE, isEmpty } from '../Util';
import Loading from '../../Loading/Loading';

function LineBarChart({ data, loading, options, plugins, errorMsg }) {
	LineBarChart.propTypes = {
		data: PropTypes.object.isRequired,
		loading: PropTypes.bool.isRequired,
		options: PropTypes.any.isRequired,
		plugins: PropTypes.any,
		errorMsg:PropTypes.any
	};
	ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
	return (
		
		<div style={{ width: '100%', height: '80%'}}>
			{loading && <Loading />}
			{!loading && !isEmpty(data.datasets[0].data) && (				
				<Chart type="bar" data={data} id="downtimeId" options={options} plugins={plugins || []}/>				
			)}
			{!loading && isEmpty(data.datasets[0].data) && isEmpty(errorMsg) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}> <span style={{fontWeight:'200', fontSize:'14px'}}> No Data Found</span></div>}
			{!loading && isEmpty(data.datasets[0].data) && !isEmpty(errorMsg) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop:'10px'}}> <span style={{color:'red',fontWeight:'200', fontSize:'14px'}}> {ERROR_MESSAGE	}</span></div>}
		</div>
		
	);
}

export default LineBarChart;
