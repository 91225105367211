import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 0
	};
	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

export const getAlert = async (params) => {
	const data =[
		{
			apiName: 'event_g.getEventCountBySeverityByHirPercent',
			sortBy:'',
			ascOrder: false,
			cursor: 0,
			pageSize: 0,
			params: {
				event_cid: params.event_cid,
				severity_level: '',
				startDate: params.startDate,
				endDate: params.endDate
			}
		}
	];
	
	const response = await callMapicsApi('post', data);	
	return response;
};

export const getAlertTableData = async (params) => {
	
	const data =[
		
		{
			apiName: 'event_g.getEventDetailsHirByFilter',
			params: {
				org_id: params.org_id,
				group_id: params.group_id,
				event_type: '',
				severity_level: [
					''
				],
				asset_type: '',
				event_status: '',
				event_category: '',
				startDate: params.startDate,
				event_cid: params.event_cid,
				endDate: params.endDate
			},
			sortBy: 'event_created_date',
			ascOrder: false,
			cursor: 0,
			pageSize: 1000
		}
					
	];
	
	const response = await callMapicsApi('post', data);	
	return response;
};

export const getDashboardAlertsData = async (params) => {
	
	const data =[
		
		{
			apiName: 'midataapp.getEventDetailsHirByFilterAlert',
			params: {
				filter_datetime: params.currentDate,
				hour_mode_duration: params.hour_mode,
				mode: params.mode,
				event_cid: params.thingId,
				filter_start: params.startDate,
				filter_end: params.endDate
			},
			sortBy: 'event_id',
			pageSize: 1000
		}
					
	];
	
	const response = await callMapicsApi('post', data);	
	return response;
};

export const getThingHierarchyLevelOneProperty = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getThingHierarchyLevelOneProperty',
			params: {
				thing_id: params.thingId,
				property: 'yes'
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};


export const getDeviceHealthDetails = async (params) => {
	const data = [
		{
			apiName : 'mfiKpi.getDeviceHealthDetails',
			params : {
				thing_id : params.thing_id,
				filterStart : params.filterStart,
				filterEnd : params.filterEnd
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};


export const getConfiguredProducts = async (params) => {
	const data = [
		{
			apiName: 'midatadb.getConfiguredProducts',
			params: {
				thingId: params.thingId
			},
			sortBy: '',
			ascOrder: true,
			cursor: 0,
			pageSize: 20
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getCycleTimeAverage = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getCycleTimeSliceConfig',
			params: {
				thingId: params.thingId,
				productId: params.productId,
				startTime: params.startTime,
				endTime: params.endTime
			},
			sortBy: '1',
			ascOrder: true
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getCycleTimeInsight = async (params) => {
	const data = [
		{
			apiName: 'midataapp.getCycleTimeInsight',
			requestBody: {
				startTimestamp: params.startTimeStamp,
				endTimestamp: params.endTimeStamp
			},
			method: 'POST',
			requestParams: params.productId === 0 ? `/dashboard/api/v2/cycletime/insight/${params.thingId}` : `/dashboard/api/v2/cycletime/insight/${params.thingId}?productId=${params.productId}`,
			header: {
				uid: params.uid
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getLineHealth = async (params) => {
	const data = [
		{
			apiName: 'mfiKpi.getDeviceHealthSummary',
			params: {
				thing_id: params.thingId,
				filterStart: params.filterStart,
				filterEnd: params.filterEnd
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};
