import React from 'react';
import PropTypes from 'prop-types';
import './RemainingLifeIndicator.css'; 
import { useNavigate, useLocation } from 'react-router-dom';

const RemainingLifeIndicator = ({ daysRemaining }) => {
	const percentage = (daysRemaining / 365) * 100;
	const navigate = useNavigate();
	const location = useLocation();

	const handleAlertClick = () => {
		navigate('/prognostics', {state: {
			...location.state
		}});
	};

	return (
		<div className="remaining-life-container">
			<div className="label-top">
				Remaining Useful Life:  
				<span ><strong className="days-remaining">{daysRemaining}</strong> Day(s)</span> 
				<span className="alert-icon" onClick={()=> handleAlertClick()} role="button" tabIndex="0">⚠️</span>
			</div>
			<div className="progress-bar">
				<div style={{ width: `${percentage}%` }}></div>
				<div className="marker" style={{ left: `${percentage}%` }}></div>
			</div>
			<div className="label-bottom">
				<span>0 Day</span>
				<span>365 Days</span>
			</div>
		</div>
	);
};

RemainingLifeIndicator.propTypes = {
	daysRemaining: PropTypes.number.isRequired ,
	alertNavigation: PropTypes.string
};

export default RemainingLifeIndicator;
