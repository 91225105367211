import React, { useState, useEffect } from 'react';
import { HvTypography ,HvGrid } from '@hitachivantara/uikit-react-core';
import KpiCard from '../shared/components/KpiCard';
import BarChart from '../shared/components/Charts/BarChart';
import PropTypes from 'prop-types';
import AssetParameters from './AssetParameters';

function AssetKpis({deviceData}) {
	AssetKpis.propTypes = {
		deviceData: PropTypes.array.isRequired
	};
	const KPItitle='Maintenance KPI\'S';
	const [loading, setLoading] = useState(true); 

	useEffect(()=> {
		const interval = setInterval(() => {
			setLoading(false);
		}, 1000);

		return () => clearInterval(interval);
	},[]);

	return (
		<div className='titleBox'style={{height: '490px'}}>
			<div id='assetParameter' style={{height: '375px'}}>
				<AssetParameters loading={loading} />
			</div>
			{false && (
				<div>
					<BarChart id="barChart" data={deviceData} loading={loading}/>
				</div>
			)}
			{!loading && <div id='KPICard' >
				<HvGrid container>
					<HvTypography variant="title3" className='util-titleBox' style={{paddingLeft: '40px'}}>
						<div style={{ display: 'flex' }}>
							<div>{KPItitle}</div>
							<div style={{ marginLeft: 'auto' }}></div>
						</div>
					</HvTypography>
					<div style={{ display:'flex', width:'100%',justifyContent:'center', alignItems:'center'}}>
						<HvGrid id="MTBF" md={5.5}>
							<KpiCard label='MTBF' daysOrHours='150 Day(s)' status='MTBFup' />
						</HvGrid>
						<HvGrid id="MTTR" md={6.5}>
							<KpiCard label='MTTR' daysOrHours='8 Hour(s)' status='MTTRup' />
						</HvGrid>
					</div>					
				</HvGrid>  
			</div>}
		</div>
	);
}

export default AssetKpis;
