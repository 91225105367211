import React, { useEffect } from 'react';
import { HvAccordion, HvGrid, HvTypography } from '@hitachivantara/uikit-react-core';
import DownTimeReason from './DownTimeReason';
// import OperationalParameters from './OperationalParameters';
import MachineLog from './MachineLog';
import Asset from './Asset';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserPreference } from '../actions';
import AlertCount from '../shared/components/AlertCount';
import Oee from './Oee';
import useToken from '../useToken';

function Machine() {
	const location = useLocation();
	// const selectedHierarchy = useSelector((state) => state.dashboard.selectedHierarchy);
	const { token } = useToken();
	const uid = token.username;
	const dispatch = useDispatch();
	const dispatchToGetUserPreference = (uid) => {
		dispatch(
			getUserPreference({
				uid: uid
			})
		);
	};

	useEffect(() => {
		dispatchToGetUserPreference(uid);
	}, [uid]);

	let maData={ 
		sliceId: 39014,
		uid: 'mi_qa'
	};
	
	return (
		<>
			<div>
				<AlertCount/>
				<HvGrid container direction="row" spacing={5}>
					<HvGrid item md={8} style={{ height: '490px',marginBottom: '70px' }}>
						<Asset imageId={location.state?.hierarchyDetails?.imageId} thingId={location.state?.hierarchyDetails?.thingId} startDate={0} endDate={0} />
					</HvGrid>
					<HvGrid item md={4} style={{ paddingLeft:'20px'}}>
						<HvAccordion
							defaultExpanded
							headingLevel={3}
							id="oee"
							label="OEE"
							labelVariant="label"
							style={{ color:'white', backgroundColor: 'white', borderRadius: '6px 6px 0px 0px', marginBottom: '10px'}}
						>
							<HvTypography variant="title3" style={{borderRadius: '0px 0px 6px 6px',
								padding: '10px',
								backgroundColor: 'white',
								width: '100%', paddingTop: '0px', minHeight:'80px'}}>
								<Oee />
							</HvTypography>
						</HvAccordion>
						<DownTimeReason	uid={uid}/>
					</HvGrid>
				</HvGrid>
				{/* <HvGrid container direction="row" spacing={5} >
					<HvGrid item md={6} style={{ height: '390px',marginBottom: '75px' }}>
						<OperationalParameters uid={uid} height='390px'/>
					</HvGrid>					
				</HvGrid> */}
				<HvGrid container direction="row" style={{ paddingTop: '0px', marginBottom:'10px', minHeight: '200px'}} spacing={5}>
					<HvGrid item md={12}>
						<MachineLog uid={uid} maData={maData} thingId={location.state?.hierarchyDetails?.thingId} /> 
					</HvGrid>
				</HvGrid>
			</div>
		</>
	);
}

export default Machine;

