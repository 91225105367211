import React, { useState } from 'react';
import './Charts.css';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import Popup from '../Popup';
//import { HvTooltip, HvTypography } from '@hitachivantara/uikit-react-core';
import { isEmpty } from '../Util';
import { Tooltip } from 'chart.js';

function MultiColorBarChart({ insigtsData }) {
	MultiColorBarChart.propTypes = {
		insigtsData: PropTypes.object.isRequired
	};
	const [open, setOpen] = useState(false);
	const lables = ['Unplanned Down', 'Unplanned Idling', 'Planned Down', 'Planned Idling', 'Normal'];
	let plannedDown = [];
	let unplannedDown = [];
	let unplannedIdling = [];
	let plannedIdling = [];
	let running = [];
	let dataInsight=null;
	let popupData=[];
	let thingName='';
	if(!isEmpty(insigtsData.insights)){
		dataInsight = JSON.parse(insigtsData.insights);
		thingName=insigtsData.thingName;
		dataInsight.map((item) => {
			if (item['UnplannedDown'] != undefined) {
				unplannedDown = item['UnplannedDown'];
			}
			if (item['UnplannedIdling'] != undefined) {
				unplannedIdling = item['UnplannedIdling'];
			}
			if (item['PlannedDown'] != undefined) {
				plannedDown = item['PlannedDown'];
			}
			if (item['PlannedIdling'] != undefined) {
				plannedIdling = item['PlannedIdling'];
			}
			if (item['Running'] != undefined) {
				running = item['Running'];
			}
		});
	}
	const barvalues = [
		getCount(unplannedDown[0]),
		getCount(unplannedIdling[0]),
		getCount(plannedDown[0]),
		getCount(plannedIdling[0]),
		getCount(running[0])
	];
	popupData=[];
	if(!isEmpty(unplannedDown[0]) && !isEmpty(unplannedDown[0].reasons)){
		popupData.push(unplannedDown[0].reasons);
	}
	if(!isEmpty(unplannedIdling[0])&& !isEmpty(unplannedIdling[0].reasons)){
		popupData.push(unplannedIdling[0].reasons);
	}
	if(!isEmpty(plannedDown[0]) && !isEmpty(plannedDown[0].reasons)){
		popupData.push(plannedDown[0].reasons);
	}
	if(!isEmpty(plannedIdling[0]) && !isEmpty(plannedIdling[0].reasons)){
		popupData.push(plannedIdling[0].reasons);
	}
	if(!isEmpty(running[0]) && !isEmpty(running[0].reasons)){
		popupData.push(running[0].reasons);
	}
	const availData = getChartData(lables, barvalues);

	const getOrCreateTooltip = (chart) => {
		let tooltipEl = chart.canvas.parentNode.querySelector('div');

		if (!tooltipEl) {
			tooltipEl = document.createElement('div');
			tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
			tooltipEl.style.borderRadius = '3px';
			tooltipEl.style.color = 'white';
			tooltipEl.style.opacity = 1;
			tooltipEl.style.pointerEvents = 'none';
			tooltipEl.style.position = 'absolute';
			tooltipEl.style.transform = 'translate(-50%, 0)';
			tooltipEl.style.transition = 'all .1s ease';

			const table = document.createElement('table');
			table.style.margin = '0px';

			tooltipEl.appendChild(table);
			chart.canvas.parentNode.appendChild(tooltipEl);
		}

		return tooltipEl;
	};

	const externalTooltipHandler = (context) => {
		// Tooltip Element
		const { chart, tooltip } = context;
		const tooltipEl = getOrCreateTooltip(chart);
		// Hide if no tooltip
		if (tooltip.opacity === 0) {
			tooltipEl.style.opacity = 0;
			return;
		}
		// Set Text
		if (tooltip.body) {
			//const titleLines = tooltip.title || [];
			const tableHead = document.createElement('thead');
			tooltipEl.style.backgroundColor='#FBFCFC';	
			tooltipEl.style.border='1px solid #E7EDF9';
			tooltipEl.style.color = 'black';
			const htr = document.createElement('tr');
			htr.style.borderWidth = 0;
			const th = document.createElement('th');
			th.style.borderWidth = 0;
			const hText = document.createTextNode(thingName);
	
			th.appendChild(hText);
			htr.appendChild(th);
			tableHead.appendChild(htr);
	
			const tableBody = document.createElement('tbody');
			const tr = document.createElement('tr');
			const tr1 = document.createElement('tr');
			const tr2 = document.createElement('tr');
			const tr3 = document.createElement('tr');
			const tr4 = document.createElement('tr');
	
			const td = document.createElement('td');
			const td0=document.createElement('td');
	
			const td1 = document.createElement('td');
			const td11 = document.createElement('td');
	
			const td2 = document.createElement('td');
			const td21 = document.createElement('td');
	
			const td3 = document.createElement('td');
			const td31 = document.createElement('td');

			const td4 = document.createElement('td');
			const td41 = document.createElement('td');
	
			const span = document.createElement('span');
	
				
			const text=document.createTextNode('Unplanned Down');
			let text0=document.createTextNode(getCount(unplannedDown[0])+ '%');
	
			const text1= document.createTextNode('Unplanned Idling');
			const text11= document.createTextNode(getCount(unplannedIdling[0])+'%');
	
			const text2= document.createTextNode('Planned Down');
			const text21= document.createTextNode(getCount(plannedDown[0])+'%');
	
			const text3= document.createTextNode('Planned Idling');
			const text31= document.createTextNode(getCount(plannedIdling[0])+'%');

			const text4= document.createTextNode('Normal');
			const text41= document.createTextNode(getCount(running[0])+'%');
	
			td.appendChild(span);
			td.appendChild(text);
			td0.appendChild(text0);
			tr.appendChild(td);
			tr.appendChild(td0);
			tableBody.appendChild(tr);
	
			td1.appendChild(span);
			td1.appendChild(text1);
			td11.appendChild(text11);
			tr1.appendChild(td1);
			tr1.appendChild(td11);
			tableBody.appendChild(tr1);
	
			td2.appendChild(span);
			td2.appendChild(text2);
			td21.appendChild(text21);
			tr2.appendChild(td2);
			tr2.appendChild(td21);
			tableBody.appendChild(tr2);
	
			td3.appendChild(span);
			td3.appendChild(text3);
			td31.appendChild(text31);
			tr3.appendChild(td3);
			tr3.appendChild(td31);
			tableBody.appendChild(tr3);

			td4.appendChild(span);
			td4.appendChild(text4);
			td41.appendChild(text41);
			tr4.appendChild(td4);
			tr4.appendChild(td41);
			tableBody.appendChild(tr4);
	
			const tableRoot = tooltipEl.querySelector('table');
	
			// Remove old children
			while (tableRoot.firstChild) {
				tableRoot.firstChild.remove();
			}
	
			// Add new children
			tableRoot.appendChild(tableHead);
			tableRoot.appendChild(tableBody);
		}
	
		const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
	
		// Display, position, and set styles for font
		tooltipEl.style.opacity = 1;
		tooltipEl.style.left = positionX + tooltip.caretX + 'px';
		tooltipEl.style.top = positionY + tooltip.caretY + 'px';
		tooltipEl.style.font = tooltip.options.bodyFont.string;
		tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
	};

	Tooltip.positioners.myCustomPositioner = function() {
		return {
			x: 600,
			y: -90
		};
	};
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: 'y',
		scales: {
			x: {
				min: 0,
				max: 100,
				stacked: true,
				ticks: {
					display: true
				},
				grid: {
					display: true
				},
				title: {
					display: true,
					text: 'Percentage (%)',
					font: {
						size: 14,
						style: 'normal'
					},
					padding: { top: 9, left: 10, right: 0, bottom: 0 }
				}
			},
			y: {
				stacked: true,
				ticks: {
					display: false
				},
				grid: {
					display: false,
					drawTicks: false
				},
				title: {
					display: true,
					text: thingName,
					font: {
						size: 14,
						style: 'normal'
					},
					padding: { top: 0, left: 0, right: 0, bottom: 9 }
				}
			}
		},
		onClick: function () {
			//console.log(e.chart.tooltip.body[0]); --don't delete
			setOpen(true);
		},
		plugins: {
			legend: {
				display: true,
				labels:{
					boxWidth:13,
					boxHeight:13,
					padding:30,
					font: {
						size:12,
						weight: 'bold'
					}
				}
			},
			datalabels: {
				display: true
			},
			tooltip: {
				enabled: false,
				position: 'myCustomPositioner',
				external: externalTooltipHandler
			}
		}
	};

	

	return (
		<>	
			<div style={{height:'200px'}}>
				<Bar id="multiHzBar" data={availData}  options={options} />
			</div>
			{open && !isEmpty(popupData) && !isEmpty(thingName) && (
				<Popup thingName={thingName} data={popupData} parentCallback={setOpen}></Popup>
			)}
		</>
	);}


function getCount(array) {
	if (array != undefined && array != null) {
		if (array.percentage != undefined && array.percentage[0] != undefined) {
			if(array.percentage[0].count !=undefined && array.percentage[0].count !=null){
				return array.percentage[0].count;
			}else{
				return 0;
			}
		} else {
			return 0;
		}
	} else {
		return 0;
	}
}

function getChartData(lables, array) {
	const chartData = {
		labels: [''],
		datasets: [
			{
				label: lables[0],
				backgroundColor: ['#FF5E6C'],
				data: [array[0]],
				barThickness: '50'
			},
			{
				label: lables[1],
				backgroundColor: ['#E68C17'],
				data: [array[1]],
				barThickness: '50'
			},
			{
				label: lables[2],
				backgroundColor: ['#82cfff'],
				data:[array[2]],
				barThickness: '50'
			},
			{
				label: lables[3],
				backgroundColor: ['gray'],
				data: [array[3]],
				barThickness: '50'
			},
			{
				label: lables[4],
				backgroundColor: ['#63A621'],
				data: [array[4]],
				barThickness: '50'
			}
		]
	};

	return chartData;
}


export default MultiColorBarChart;
