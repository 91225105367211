import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getImageById } from '../actions/index';
import PropTypes from 'prop-types';
import '../App.css';
import { checkBarStatus } from '../shared/components/Util';
import { HvTypography } from '@hitachivantara/uikit-react-core';
import Loading from '../shared/Loading/Loading';
import brokenImage from '../assets/images/brokenImage.png';
import RemainingLifeIndicator from '../shared/components/RemainingUsefulLifeIndicator';

function AssetView({ imageId, data }) {
	AssetView.propTypes = {
		imageId: PropTypes.string.isRequired,
		data: PropTypes.array.isRequired
	};

	const title='Asset View';
	const [imageData, setImageData] = useState('');
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const getimagebyid = useSelector((state) => state.machine.getimagebyid);
	const canvasRef = useRef(null);
	const canvasWidth = 412;
	const canvasHeight = 319;
	const [originalImageWidth, setOriginalImageWidth] = useState(null);
	const [originalImageHeight, setOriginalImageHeight] = useState(null);
	const [isChecked, setIsChecked] = useState({
		healthy: false,
		warning: false,
		critical: true
	});
	const [showCriticalData, setShowCriticalData] = useState(true);
	const [showHealthyData, setShowHealthyData] = useState(false);
	const [showWarningData, setShowWarningData] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [hoveredMarker, setHoveredMarker] = useState(null);
	const dispatchToGetImageById = (imageId) => {
		dispatch(getImageById({ id: imageId }));
	};

	useEffect(() => {
		setImageData('');
		dispatchToGetImageById(imageId);
	}, [imageId]);

	useEffect(() => {
		setLoading(true);
		if (imageId == 0 || imageId == null || imageId == undefined) {
			setLoading(false);
		} else {
			dispatchToGetImageById(imageId);
		}
	}, [imageId]);

	useEffect(() => {
		if (getimagebyid && getimagebyid.length > 0) {
			setImageData(getimagebyid[0].image);
			setLoading(false);
		}
	}, [getimagebyid]);

	function calculateDataArray(data) {
		return data.map((item) => [
			item.device_id,
			checkBarStatus(item),
			item.x_axis,
			item.y_axis,
			item.sensor_name,
			parseFloat(item.value).toFixed(2),
			item.unit_of_measure
		]);
	}

	const dataArray = React.useMemo(() => calculateDataArray(data), [data]);

	const handleCheckBoxChange = (event) => {
		const { name, checked } = event.target;
		setIsChecked({
			...isChecked,
			[name]: checked
		});

		if (name === 'critical') {
			setShowCriticalData(checked);
		} else if (name === 'healthy') {
			setShowHealthyData(checked);
		} else if (name === 'warning') {
			setShowWarningData(checked);
		}
	};

	useEffect(() => {
		const filteredMarkers = dataArray.filter((item) => {
			if (showCriticalData && item[1] === 'Critical') {
				return true;
			} else if (showHealthyData && item[1] === 'Healthy') {
				return true;
			} else if (showWarningData && item[1] === 'Warning') {
				return true;
			}
			return false;
		});

		setMarkers(filteredMarkers);
	}, [dataArray, showCriticalData, showHealthyData, showWarningData]);

	useEffect(() => {
		drawImageOnCanvas();
	}, [imageData, originalImageWidth, originalImageHeight]);

	const drawImageOnCanvas = () => {
		if (!canvasRef.current || !imageData) {
			return;
		}

		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		canvas.width = canvasWidth;
		canvas.height = canvasHeight;

		const img = new Image();
		img.onload = () => {
			ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);

			setOriginalImageWidth(img.naturalWidth);
			setOriginalImageHeight(img.naturalHeight);
		};

		img.src = `data:image/png;base64, ${imageData}`;
	};

	function getMarkerCardTransform() {
		if (hoveredMarker) {
			const markerX = (hoveredMarker[2] * canvasWidth) / originalImageWidth;
			const markerY = (hoveredMarker[3] * canvasHeight) / originalImageHeight;

			const cardWidth = 170;

			let translateX = 10;
			let translateY = -100;

			if (markerX + cardWidth > canvasWidth) {
				translateX = -cardWidth - 10;
			} else if (markerX - cardWidth < 0) {
				translateX = 10;
			} else if (markerY > canvasHeight - 100) {
				translateY = -100;
			} else if (markerY - 100 < 0) {
				translateY = 10;
			}
			return `translate(${translateX}px, ${translateY}px)`;
		}
		return 'translate(10px, -100%)';
	}

	return (
		<div className='titleBox2'>
			<HvTypography variant="title3" className='util-titleBox' style={{ paddingBottom:'0px'}}>
				<div style={{ display: 'flex' }}>
					<div>{title}
						<div className="checkboxes line">
							<label className={`checkbox-label ${isChecked.critical ? 'checked' : ''} ${isChecked.critical ? 'red' : ''}`}>
								<input
									type="checkbox"
									name="critical"
									checked={isChecked.critical}
									onChange={handleCheckBoxChange}
								/>Critical
							</label>
							<label className={`checkbox-label ${isChecked.warning ? 'checked' : ''} ${isChecked.warning ? 'orange' : ''}`}>
								<input
									type="checkbox"
									name="warning"
									checked={isChecked.warning}
									onChange={handleCheckBoxChange}
								/>Warning
							</label>
							<label className={`checkbox-label ${isChecked.healthy ? 'checked' : ''} ${isChecked.healthy ? 'green' : ''}`}>
								<input
									type="checkbox"
									name="healthy"
									checked={isChecked.healthy}
									onChange={handleCheckBoxChange}
								/>Healthy
							</label>
						</div>
					</div>
				</div>
			</HvTypography>
			<div className='util-chart4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				{loading && <Loading />}
				{!loading && imageData && (
					<div
						style={{
							position: 'relative',
							width:'100%',
							height: '100%'
						}}>
						<canvas ref={canvasRef} style={{minHeight:'100%'}} />
						{markers.map((marker, index) => {
							const x = marker[2];
							const y = marker[3];
							const status = marker[1];

							const markerX = (x * canvasWidth) / originalImageWidth;
							const markerY = (y * canvasHeight) / originalImageHeight;

							return (
								<div
									key={index}
									style={{
										position: 'absolute',
										top: `${markerY}px`,
										left: `${markerX}px`,
										width: '20px',
										height: '20px',
										backgroundColor:
										status === 'Critical' ? 'rgb(255, 82, 82)' : status === 'Healthy' ? 'rgb(99, 166, 33)' : 'rgb(231, 120, 77)',
										borderRadius: '50%',
										transform: 'translate(-50%, -50%)',
										pointerEvents: 'auto',
										cursor: 'pointer'
									}}
									onMouseEnter={() => setHoveredMarker(marker)}
									onMouseLeave={() => setHoveredMarker(null)}></div>
							);
						})}
						{hoveredMarker && (
							<div
								className="marker-card"
								style={{
									position: 'absolute',
									top: `${(hoveredMarker[3] * canvasHeight) / originalImageHeight}px`,
									left: `${(hoveredMarker[2] * canvasWidth) / originalImageWidth}px`,
									backgroundColor: 'white',
									padding: '10px',
									borderRadius: '4px',
									pointerEvents: 'none',
									width: '170px',
									transform: getMarkerCardTransform()
								}}>
								<p>{hoveredMarker[4]}</p>
								<p>
								Value: {parseFloat(hoveredMarker[5]).toFixed(2)} {hoveredMarker[6]}
								</p>
							</div>
						)}
					</div>
				)}
				{!loading && !imageData && ( <div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						height: `${canvasHeight}px`,
						fontWeight: 'bold',
						fontSize: '24px'
					}}
				>
					<div>
						<img src = {brokenImage} style={{paddingLeft:20,height: 'auto',width:'auto'}}/><br/>
						<h1>No Image Uploaded</h1>
					</div>
				</div>
				)}
			</div>
			{!loading && <div id='RUL' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<RemainingLifeIndicator daysRemaining={180} alertNavigation='\prognostics' />
			</div>}
		</div>
	);
}

export default AssetView;

