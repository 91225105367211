import { HvDropdown, HvTypography } from '@hitachivantara/uikit-react-core';
import { HvVizProvider } from '@hitachivantara/uikit-react-viz';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import DatePicker from '../shared/components/DatePicker/DatePicker';
import LineBarChart from '../shared/components/Charts/LineBarChart';
import PropTypes from 'prop-types';
import { getQualityByDefect, getQualityProdDropdown } from '../actions';
import { getQualityDefectForAllProduct } from '../api/QualityAnalytics';
import Loading from '../shared/Loading/Loading';
import { isEmpty } from '../shared/components/Util';
import downloadImage from '../assets/images/excel_icon.png';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import '../App.css';
import { useLocation } from 'react-router-dom';

const options = {
	responsive: true,
	interaction: {
		mode: 'index',
		intersect: true
	},
	stacked: true,
	plugins: {
		legend: {
			display: true,
			labels: {
				boxWidth: 10,
				boxHeight: 2
			}
		},
		datalabels: {
			display: false
		},
		tooltip: {
			cornerRadius: 4,
			backgroundColor: '#FFFFFF',
			titleColor: 'black',
			titleFont: {
				family: '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
				weight: 10,
				size: 10,
				style: 'normal'
			},
			xAlign: 'left',
			yAlign: 'top',
			width: '100px',
			height: '25px',
			bodyColor: 'black',
			bodyFont: { size: 8 },
			boxWidth: 14,
			boxHeight: 1
		}
	},
	scales: {
		y: {
			type: 'linear',
			display: true,
			position: 'left',
			title:{
				display: true,
				text: 'Defects Count'
			}
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			grid: {
				drawOnChartArea: false
			},
			title:{
				display: true,
				text: 'Reason Percentage'
			}
		},
		x: {
			barPercentage: 0.1,
			stacked: true,
			grid: {
				display: false
			}
		}
	}
};
let dataList = [];

function QualityByDefect({ uid }) {
	QualityByDefect.propTypes = {
		uid: PropTypes.string.isRequired
	};

	const location = useLocation();
	const [trendingData, setTrendingData] = useState([]);
	const [qualityDataList, setQualityDataList] = useState([]);
	const [ loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState({ id: 0, label: 'All Products' });
	const qualityDefectData = useSelector((state) => state.qualityAnalytics.qualityDefectData);
	const qualityProdDropdown = useSelector((state) => state.qualityAnalytics.qualityProdDropdown);
	const windowTimestamps = useSelector((state) => state.dashboard.windowTimestamps);
	const featureEnabled = useSelector((state) => state.dashboard.featureEnabled);
	const [thingId, setThingId] = useState(null);
	const dispatch = useDispatch();
	const [toggledOffData, setToggledOffData] = useState([]);

	const handleOptionClickToggledOff = (params) => {
		setToggledOffData({ ...params });
	};

	useEffect(() => {
		if (location.state !== null) {
			setThingId(location.state?.qaFactory?.thingId);
		}
	}, [location]);

	useEffect(() => {
		if (thingId !== null && thingId !== undefined) {
			dispatchToGetProdDropdownData();
		}
	}, [thingId]);

	const dispatchToGetProdDropdownData = () => {
		dispatch(
			getQualityProdDropdown({
				thingId
			})
		);
	};

	useEffect(() => {
		if (qualityDefectData.length == 0) {
			setTrendingData([]);
			return;
		}
		if (qualityDefectData) {
			let parsedData = JSON.parse(qualityDefectData);
			if (parsedData?.data) {
				setTrendingData(parsedData.data);
			}
		}
		setLoading(false);
	}, [qualityDefectData]);

	useEffect(() => {
		if (windowTimestamps.length > 0 && dataList.length > 0) {
			setLoading(true);
			dispatchToGetQualityDefectlist(dataList[0]);
		}
	}, [windowTimestamps, toggledOffData]);

	const dispatchToGetQualityDefectlist = (params) => {
		if (windowTimestamps.length > 0 && isEmpty(toggledOffData)) {
			dispatch(
				getQualityByDefect({
					productId: params.id,
					flowId: 0,
					sliceId: 23,
					thingId: thingId,
					endTimestamp: windowTimestamps[0]?.end_datetime_ep,
					startTimestamp: windowTimestamps[0]?.start_datetime_ep
				})
			);
		} else if (!isEmpty(toggledOffData)) {
			dispatch(
				getQualityByDefect({
					productId: params.id,
					flowId: 0,
					sliceId: 23,
					thingId: thingId,
					endTimestamp: toggledOffData.wts[0]?.end_datetime_ep,
					startTimestamp: toggledOffData.wts[0]?.start_datetime_ep
				})
			);
		}
	};

	useEffect(() => {
		if (qualityProdDropdown.length > 0) {
			let latData = qualityProdDropdown.map((item) => {
				var obj = { ...item };
				obj.id = item.product_id;
				obj.label = item.product_name;
				return obj;
			}, []);
			dataList = [{ id: 0, label: 'All Products' }, ...latData];
			selectTrendingProduct({ ...dataList[0], selected: true });
		} else {
			setQualityDataList([]);
		}
	}, [qualityProdDropdown]);

	const selectTrendingProduct = (item) => {
		let updatedList =
			dataList &&
			dataList.map((items) => {
				return items.id === item.id ? item : items;
			});
		setQualityDataList(updatedList);
	};

	const labels =
		trendingData && trendingData.length > 0 &&
		trendingData.map((item) => {
			return item.label;
		});
	
	const sortedTrendingData = trendingData
		.slice() 
		.sort((a, b) => b.count - a.count);
	
	let totalDefectsCount = 0;

	sortedTrendingData.forEach((item) =>{
		totalDefectsCount += item.count;
	});
	
	let totalCount = 0;

	const paretoData = sortedTrendingData.map((item) => {
		totalCount += item.count;
		return (totalCount/ totalDefectsCount) *100;
	});
	
	const byDefectApiData = {
		labels,
		paretoDataset:[{
			label: 'Pareto',
			type: 'bar',
			data: paretoData,
			backgroundColor: 'rgba(75, 192, 192, 0.5)',
			yAxisID: 'y1'		
		}],
		datasets: [
			{
				label: 'Reason %',
				type: 'line',
				data: paretoData,  
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
				yAxisID: 'y1',
				fill: false  
			},
			{
				label: 'Defects(Count)',
				data: trendingData && trendingData.map((item) => item.count),
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
				yAxisID: 'y',
				barThickness: '10',
				categoryPercentage: '0.8' 
			}
		]
	};

	const selectDefectProduct = (item) => {
		if (item === undefined) {
			setQualityDataList([...qualityDataList]);
			return;
		}
		let updatedList = dataList && dataList.map((items) => {
			return items.id === item.id ? item : items;
		});
		setQualityDataList(updatedList);
		setSelectedProduct(item);
		dispatchToGetQualityDefectlist(item);
	};

	async function convertToExcelArray() {
		let XLArr = [];
		let sheetData = [];
		setIsLoading(true);
		let requestPayload = [];
		let productNameList = [];
		if(selectedProduct.id === 0){
			if(qualityDataList.length){
				qualityDataList.forEach((list) => {
					if(list.id !== 0){
						productNameList.push(list.label);
						const payload = {
							productId: list.id,
							flowId: 0,
							sliceId: 23,
							thingId: thingId,
							endTimestamp: windowTimestamps[0]?.end_datetime_ep || toggledOffData.wts[0]?.end_datetime_ep,
							startTimestamp: windowTimestamps[0]?.start_datetime_ep || toggledOffData.wts[0]?.start_datetime_ep
						};
						requestPayload.push(payload);
					}				
				});
			}
			if(requestPayload.length){
				getQualityDefectForAllProduct(requestPayload)
					.then((result) => {
						let excelTrendingData = [];
						result?.map((data,i) => {
							let parsedData = JSON.parse(data?.resultSet);
							if (parsedData.data.length) {
								const productData = {
									productName: productNameList[i],
									data : parsedData?.data
								};
								excelTrendingData.push(productData);
							}
						});
						if(excelTrendingData.length){
							excelTrendingData?.map((item) => {	
								const productName = item.productName;
								item.data?.map((itemData) => {
									let new_object = {};		
									new_object['Product Name'] = productName;
									new_object['Defect Name'] = itemData.label;
									new_object['Count'] = itemData.count;
									XLArr.push(new_object);
								});	
								
							});
						}
						else{
							let new_object = {};
							new_object['Product Name'] = '';
							new_object['Defect Name'] = '';
							new_object['Count'] = '';
							XLArr.push(new_object);
						}
						sheetData = XLSX.utils.json_to_sheet(XLArr, { header: Object.keys(XLArr[0]) });
						const excelArray = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

						const workbook = XLSX.utils.book_new();
						const worksheet = XLSX.utils.aoa_to_sheet(excelArray);
						XLSX.utils.book_append_sheet(workbook, worksheet, 'Quality By Defect');

						const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
						const blob = new Blob([excelBuffer], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						});
	
						// Use FileSaver.js to save the file
						saveAs(blob, `${'Quality By Defect' + ' - ' + new Date().getTime()}.xlsx`);				
						setIsLoading(false);
					})
					.catch(() => {
						setIsLoading(false);
					});				
			}
		}
		else if(!isEmpty(trendingData)){
			trendingData?.map((item) => {		
				let new_object = {};		
				new_object['Product Name'] = selectedProduct.label;
				new_object['Defect Name'] = item.label;
				new_object['Count'] = item.count;
				XLArr.push(new_object);
			});
			setIsLoading(false);

			sheetData = XLSX.utils.json_to_sheet(XLArr, { header: Object.keys(XLArr[0]) });
			const excelArray = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

			return excelArray;
		}
		else {			
			let new_object = {};
			new_object['Product Name'] = '';
			new_object['Defect Name'] = '';
			new_object['Count'] = '';
			XLArr.push(new_object);
			setIsLoading(false);

			sheetData = XLSX.utils.json_to_sheet(XLArr, { header: Object.keys(XLArr[0]) });
			const excelArray = XLSX.utils.sheet_to_json(sheetData, { header: 1 });

			return excelArray;
		}
	}

	async function generateExcelData() {
		const data = await convertToExcelArray();
		if(selectedProduct.id !== 0){
			const workbook = XLSX.utils.book_new();
			const worksheet = XLSX.utils.aoa_to_sheet(data);
			XLSX.utils.book_append_sheet(workbook, worksheet, 'Quality By Defect');
			return workbook;
		}		
	}

	async function onDownloadXL(fileName){
		const workbook = await generateExcelData();
		if(selectedProduct.id !== 0){
			const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			});
	
			// Use FileSaver.js to save the file
			saveAs(blob, `${fileName + ' - ' + new Date().getTime()}.xlsx`);
		}		
	}

	if (thingId !== null && thingId !== undefined) {
		return (
			<HvVizProvider>
				<HvTypography variant="title3" className="titleBox" style={{ height: '530px' }}>
					<div className="titleContentCss"> Quality by Defect 
						<div style={{display:'flex',marginLeft: '10px'}}>
							{isLoading && <Loading /> }
							{!isLoading && <div style={{ marginTop: '35px'}}>
								<img alt="Export to Excel"
									src={downloadImage}
									onClick={() => onDownloadXL('Quality By Defect')}
									className="imgTag"
								/>
							</div>}
							<div style={{ display: 'flex', marginLeft: '10px' , paddingTop:'5px'}}>
								<DatePicker featureEnabled={featureEnabled} uid={uid} thingId={thingId} handleOptionClickToggledOff={handleOptionClickToggledOff} style={{ width: '200px' }}/>
							</div>
						</div>
					</div>
					<div className="titleContentCss">
						<HvDropdown
							aria-label="Single selection"
							id="dropdown7"
							style={{ width: '200px' }}
							onClick={() => { }}
							onChange={selectDefectProduct}
							values={qualityDataList}
						/>
					</div>

					<div className="chartBox" style={{ marginTop:'5px'}}>
						{trendingData && (
							<div style={{ height: '100%'}}>
								<LineBarChart data={byDefectApiData} loading={loading} options={options} />
							</div>
						)}
					</div>
				</HvTypography>
			</HvVizProvider>
		);
	} else {
		return <Loading />;
	}
}

export default QualityByDefect;

