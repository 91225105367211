import axios from 'axios';

async function callMapicsApi(method, data) {
	const headers = {
		'Content-Type': 'application/json',
		role_name: 'All',
		user_name: 'All',
		org_id: 0
	};

	if (method === 'post') {
		const response = await axios.post(
			process.env.REACT_APP_MAPICS_URL + '/mapics',
			data,
			{
				headers: headers
			}
		);
		return response.data;
	}
}

export const getTankSearchResultsApi = async (params) => { // 
	const data = [
		{
			apiName: 'heapp.getHeData',
			requestBody: {
				timestampRequest: {
					currentTimestamp: params.currentTimestamp,// 1699017991000,
					mode: params.mode,//'Custom',
					endTimestamp: params.endTimestamp,//1699017991000,
					startTimestamp: params.startTimestamp// 1672549260000
				},
				pageSize: 1000,
				sortBy: 'FIN_START',
				sortDirection: 'DESC',
				thingId: parseInt(params.thingId) //364 - UAT, 101 - DEV
			},
			method: 'POST',
			requestParams: '/dashboard/api/hapg/fins',
			header: {
				uid: '1212'
			}
		}
	];
	if(params.finSn){
		data[0].requestBody.finSn = params.finSn;
	}
	if(params.tankSn){
		data[0].requestBody.tankSn = params.tankSn;
	}
	if(params.operatorId){
		data[0].requestBody.operatorId = params.operatorId;
	}
	const response = await callMapicsApi('post', data);
	return response;
};

export const getAllMachinesData = async (params) => {
	const data = [
		{
			'apiName': 'heapp.getHeData',
			'params': {},
			'requestBody': {
				'finId':params.finId //3116
			},
			'method': 'POST',
			'requestParams': '/dashboard/api/hapg/fin-history',
			'header': {
				'uid': 1212
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getAllWeldsData = async (params) => {
	const data = [
		{
			'apiName': 'heapp.getHeData',
			'params': {},
			'requestBody': {
				'tankId':params.tankId //1
			},
			'method': 'POST',
			'requestParams': '/dashboard/api/hapg/fin-attach-history',
			'header': {
				'uid': 1212
			}
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};

export const getTable9ChartDropdownData = async (params) => {
	const data = [
		{
			'apiName': 'midataapp.getTable9',
			'params': {
				'thingid': params.thingId
			},
			'ascOrder': 'False'
		}
	];
	const response = await callMapicsApi('post', data);
	return response;
};


export const getMachinesChartData = async (params) => {
	const data = [];
	if(params && params?.machineExternalId.length){
		params.machineExternalId.forEach((item) => {
			const dataRequest = {
				'apiName': 'event_g.getAssetSecondTimeseries',
				'params': {
					'id': item,
					'startDate': params.startDate,
					'endDate': params.endDate,
					'timespanMode': 'Min'
				}
			};
			data.push(dataRequest);
		});
	}
	
	const response = await callMapicsApi('post', data);
	return response;
};

export const getMachinesAlertData = async (params) => {
	const data = [
		{
			'apiName': 'event_g.getEventDetailsHirByFilter',
			'params': {
				'org_id': 1,
				'group_id': 1,
				'event_type': '',
				'severity_level': [''],
				'asset_type': '',
				'event_status': '',
				'event_category': '',
				'startDate': params.startDate,
				'event_cid': params.event_cid,
				'endDate': params.endDate
			},
			'sortBy': 'event_created_date',
			'ascOrder': false,
			'cursor': 0,
			'pageSize': 1000
		}
	];
	const response = await callMapicsApi('post', data);

	return response;
};