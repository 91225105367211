import { HvTooltip, HvTypography } from '@hitachivantara/uikit-react-core';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import './Charts.css';
import moment from 'moment';
import { isEmptyReturnZero } from '../Util';

function PopupBarChart({ logo, toolTipData, color }) {
	PopupBarChart.propTypes = {
		logo: PropTypes.any.isRequired,
		toolTipData: PropTypes.any.isRequired,
		color: PropTypes.string.isRequired
	};
	const canvas = document.createElement('canvas');

	const chartData = getChartData(color, toolTipData, canvas);
	let options = getOptions(toolTipData);

	return (
		<>
			<HvTooltip
				placement="left-end"
				title={
					<div className="HvTooltip-valueWrapper">
						<div className="HvTooltip-values">
							<HvTypography variant="label">{toolTipData.kpi_label} </HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label" style={{ fontSize: '20px' }}>
								{toolTipData.value} {toolTipData.unit_of_measure}
							</HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								<img
									alt="logo"
									src={logo}
									style={{ height: '50px', padding: '11px 8px', marginTop: '-14px' }}></img>
								<span style={{ marginTop: '2px' }}>{color}</span>
							</HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label">Time:- </HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								{moment(toolTipData.lastTime).format('MMMM Do YYYY, h:mm:ss a')}
							</HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label">Max.Critical</HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								{isEmptyReturnZero(toolTipData.upper_critical_threshold)}
								{toolTipData.unit_of_measure}
							</HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label">Max.Warning</HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								{isEmptyReturnZero(toolTipData.upper_warning_threshold)}
								{toolTipData.unit_of_measure}
							</HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label">Min.Warning</HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								{isEmptyReturnZero(toolTipData.lower_warning_threshold)}
								{toolTipData.unit_of_measure}
							</HvTypography>
						</div>
						<div className="HvTooltip-values">
							<HvTypography variant="label">Min.Critical</HvTypography>
							<div className="HvTooltip-separator" />
							<HvTypography>
								{isEmptyReturnZero(toolTipData.lower_critical_threshold)}
								{toolTipData.unit_of_measure}
							</HvTypography>
						</div>
					</div>
				}>
				{!isEmpty(chartData) && !isEmpty(chartData) && (
					<div id="unitbar">
						<Bar data={chartData} options={options} />
					</div>
				)}
			</HvTooltip>
		</>
	);
}

function isEmpty(value) {
	let flag = false;
	if (value == null || value == undefined) {
		flag = true;
	} else if (value != null && value != undefined) {
		if (value != '') {
			if (value.length != 0) {
				flag = false;
			} else {
				flag = true;
			}
		} else {
			flag = true;
		}
	}
	return flag;
}


function getbarcolor(canvas, color) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 300);
	let code = '';
	let subCode = '';
	if (color === 'Critical') {
		code = 'rgba(255, 94, 108, 1)';
		subCode = 'rgba(255, 94, 108, 0)';
	} else if (color === 'Warning') {
		code = 'rgba(230, 140, 23, 1)';
		subCode = 'rgba(230, 140, 23, 0)';
	} else if (color === 'Healthy') {
		code = 'rgba(99, 166, 33, 1)';
		subCode = 'rgba(99, 166, 33, 0)';
	} else {
		code = 'rgba(255, 255, 255, 1)';
		subCode = 'rgba(255, 255, 255, 1)';
	}
	gradient.addColorStop(0.4, code);
	gradient.addColorStop(0.4, code);
	gradient.addColorStop(0.54, subCode);
	gradient.addColorStop(1, subCode);
	return gradient;
}

function getbarcolorLowCri(canvas, color) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 300);
	let code = '';
	let subCode = '';
	if (color === 'Critical') {
		code = 'rgba(255, 94, 108, 1)';
		subCode = 'rgba(255, 94, 108, 0)';
	} else if (color === 'Warning') {
		code = 'rgba(230, 140, 23, 1)';
		subCode = 'rgba(230, 140, 23, 0)';
	} else if (color === 'Healthy') {
		code = 'rgba(99, 166, 33, 1)';
		subCode = 'rgba(99, 166, 33, 0)';
	} else {
		code = 'rgba(255, 255, 255, 1)';
		subCode = 'rgba(255, 255, 255, 1)';
	}
	gradient.addColorStop(0.65, code);
	gradient.addColorStop(0.77, code);
	gradient.addColorStop(0.91, subCode);
	gradient.addColorStop(1.0, subCode);
	return gradient;
}


function getbarcolorUpCri(canvas, color) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 300);
	let code = '';
	let subCode = '';
	if (color === 'Critical') {
		code = 'rgba(255, 94, 108, 1)';
		subCode = 'rgba(255, 94, 108, 0)';
	} else if (color === 'Warning') {
		code = 'rgba(230, 140, 23, 1)';
		subCode = 'rgba(230, 140, 23, 0)';
	} else if (color === 'Healthy') {
		code = 'rgba(99, 166, 33, 1)';
		subCode = 'rgba(99, 166, 33, 0)';
	} else {
		code = 'rgba(255, 255, 255, 1)';
		subCode = 'rgba(255, 255, 255, 1)';
	}
	gradient.addColorStop(0.02, code);
	gradient.addColorStop(0.22, code);
	gradient.addColorStop(0.15, subCode);
	gradient.addColorStop(1, subCode);
	return gradient;
}

function getbarUpWarcolor(canvas,color) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 300);
	let code = '';
	let subCode = '';
	if (color === 'Critical') {
		code = 'rgba(255, 94, 108, 1)';
		subCode = 'rgba(255, 94, 108, 0)';
	} else if (color === 'Warning') {
		code = 'rgba(230, 140, 23, 1)';
		subCode = 'rgba(230, 140, 23, 0)';
	} else if (color === 'Healthy') {
		code = 'rgba(99, 166, 33, 1)';
		subCode = 'rgba(99, 166, 33, 0)';
	} else {
		code = 'rgba(255, 255, 255, 1)';
		subCode = 'rgba(255, 255, 255, 1)';
	}
	gradient.addColorStop(0.2, code);
	gradient.addColorStop(0.1, code);
	gradient.addColorStop(0.36, subCode);
	gradient.addColorStop(0.9, subCode);
	return gradient;
}

function getbarLowWarcolor(canvas, color) {
	const ctx = canvas.getContext('2d');
	const gradient = ctx.createLinearGradient(0, 0, 0, 300);
	let code = '';
	let subCode = '';
	if (color === 'Critical') {
		code = 'rgba(255, 94, 108, 1)';
		subCode = 'rgba(255, 94, 108, 0)';
	} else if (color === 'Warning') {
		code = 'rgba(230, 140, 23, 1)';
		subCode = 'rgba(230, 140, 23, 0)';
	} else if (color === 'Healthy') {
		code = 'rgba(99, 166, 33, 1)';
		subCode = 'rgba(99, 166, 33, 0)';
	} else {
		code = 'rgba(255, 255, 255, 1)';
		subCode = 'rgba(255, 255, 255, 1)';
	}
	gradient.addColorStop(0.3, code);
	gradient.addColorStop(0.55, code);
	gradient.addColorStop(0.73, subCode);
	gradient.addColorStop(1, subCode);
	return gradient;
}
function getChartData(color, data, canvas) {
	let color1='Normal';
	let color2='Normal';
	let color3='Normal';
	let color4='Normal';
	let color5='Normal';

	let data1;
	let data2;
	let data3;
	let data4;
	let data5;

	if (color === 'Critical') {
		if (data.value <= data.lower_critical_threshold) {
			color1 = 'Critical';
			data1=12;
			data2=0;
			data3=0;
			data4=0;
			data5=0;
		} else if (data.value >= data.upper_critical_threshold) {
			color5 = 'Critical';
			data1=12;
			data2=25;
			data3=37;
			data4=51;
			data5=60;
		}
	} else if (color === 'Warning') {
		if (data.value <= data.lower_warning_threshold) {
			color2 = 'Warning';
			data1=12;
			data2=25;
			data3=0;
			data4=0;
			data5=0;
		}else if (data.value >= data.upper_warning_threshold) {
			color4 = 'Warning';
			data1=12;
			data2=25;
			data3=37;
			data4=51;
			data5=0;
		}
	} else if (color === 'Healthy') {
		color3 = 'Healthy';
		data1=12;
		data2=25;
		data3=37;
		data4=0;
		data5=0;
	} else {
		data1=12;
		data2=25;
		data3=37;
		data4=0;
		data5=0;
	}

	const chartData = {
		labels: [''],
		datasets: [
			{
				label: '',
				backgroundColor: getbarcolorLowCri(canvas, color1),
				hoverBackgroundColor: getbarcolorLowCri(canvas, color1),
				data: [isEmptyReturnZero(data1)],
				barThickness: '25'
			},
			{
				label: '',
				backgroundColor: getbarLowWarcolor(canvas, color2),
				hoverBackgroundColor: getbarLowWarcolor(canvas, color2),
				data: [isEmptyReturnZero(data2)],
				barThickness: '25'
			},
			{
				label: '',
				backgroundColor: getbarcolor(canvas, color3), 
				hoverBackgroundColor: getbarcolor(canvas, color3),
				data: [isEmptyReturnZero(data3)], 
				barThickness: '25'
			},
			{
				label: '',
				backgroundColor: getbarUpWarcolor(canvas, color4), 
				hoverBackgroundColor: getbarUpWarcolor(canvas, color4),
				data: [isEmptyReturnZero(data4)], 
				barThickness: '25'
			},
			{
				label: '',
				backgroundColor: getbarcolorUpCri(canvas, color5), 
				hoverBackgroundColor: getbarcolorUpCri(canvas, color5),
				data: [isEmptyReturnZero(data5)], 
				barThickness: '25'
			}
		]
	};

	return chartData;
}

function getOptions() {
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		indexAxis: 'x',
		scales: {
			x: {
				stacked: true,
				ticks: {
					display: false //show/hide for footer lables
				},
				grid: {
					display: false
				}
			},
			y: {
				stacked: false,
				ticks: {
					display: false
				},
				grid: {
					display: false,
					drawTicks: false
				}
			}
		},

		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false
			},
			annotation: {
				annotations: {
					LowWarning: {
						type: 'line',
						yMin: isEmptyReturnZero(12),
						yMax: isEmptyReturnZero(12),
						borderWidth: 1,
						borderColor: '#000000',
						borderDash: [10, 0]
					},
					LowCritical: {
						type: 'line',
						yMin: isEmptyReturnZero(25),
						yMax: isEmptyReturnZero(25),
						borderWidth: 1,
						borderColor: '#000000',
						borderDash: [3, 3]
					},
					MaxWarning: {
						type: 'line',
						yMin: isEmptyReturnZero(37),
						yMax: isEmptyReturnZero(37),
						borderWidth: 1,
						borderColor: '#000000',
						borderDash: [3, 3]
					},
					MaxCritical: {
						type: 'line',
						yMin: isEmptyReturnZero(51),
						yMax: isEmptyReturnZero(51),
						borderWidth: 1,
						borderColor: '#000000',
						borderDash: [10, 0]
					}
				}
			}
		}
	};

	return options;
}

export default PopupBarChart;
