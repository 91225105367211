const initialState = {
	loading: false,
	windowTsLoading:false,
	distributionMap: [],
	hierarchy: [],
	selectedHierarchy: {},
	oeeValuesLoading: true,
	oeeValues:[],
	userPreference: {},
	windowTimestamps: [],
	featureEnabled: false,
	adminCompany: [],
	factoryProduct: [],
	productionVolume: null,
	enterpriseProduct: [],
	verticalNavigation: [],
	alertCount: 0,
	productionVolumeByFactories: null,
	productionVolumeByLines: null
};

const dashboardReducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'UPDATE_ALERT_COUNT':
		return {
			...state,
			alertCount: payload
		};
	case 'FETCH_FACTORY_DISTRIBUTION_MAP_REQUESTED':
		return {
			...state,
			loading: true,
			distributionMap: []
		};
	case 'FETCH_FACTORY_DISTRIBUTION_MAP_SUCCEEDED':
		return {
			...state,
			loading: false,
			distributionMap: payload
		};
	case 'FETCH_FACTORY_DISTRIBUTION_MAP_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_OEE_VALUES_REQUESTED':
		return {
			...state,
			oeeValuesLoading: true,
			oeeValues: []
		};
	case 'FETCH_OEE_VALUES_SUCCEEDED':
		return {
			...state,
			oeeValuesLoading: false,
			oeeValues: payload
		};
	case 'FETCH_OEE_VALUES_FAILED':
		return {
			...state,
			oeeValuesLoading: false
		};
	case 'FETCH_HIERARCHY_BY_ID_REQUESTED':
		return {
			...state,
			loading: true,
			hierarchy: []
		};
	case 'FETCH_HIERARCHY_BY_ID_SUCCEEDED':
		return {
			...state,
			loading: false,
			hierarchy: payload
		};
	case 'FETCH_HIERARCHY_BY_ID_FAILED':
		return {
			...state,
			loading: false
		};
	case 'UPDATE_SELECTED_HIERARCHY':
		return {
			...state,
			selectedHierarchy: payload
		};
	case 'FETCH_USER_PREFERENCE_REQUESTED':
		return {
			...state,
			loading: true,
			userPreference: {}
		};
	case 'FETCH_USER_PREFERENCE_SUCCEEDED':
		return {
			...state,
			loading: false,
			userPreference: payload
		};
	case 'FETCH_USER_PREFERENCE_FAILED':
		return {
			...state,
			loading: false
		};
	case 'UPDATE_USER_PREFERENCE_REQUESTED':
		return {
			...state,
			loading: true
		};
	case 'UPDATE_USER_PREFERENCE_SUCCEEDED':
		return {
			...state,
			loading: false,
			userPreference: payload
		};	
	case 'UPDATE_USER_PREFERENCE_FAILED':
		return {
			...state,
			loading: false
		};
	case 'FETCH_WINDOW_TIMESTAMP_REQUESTED':
		return {
			...state,
			loading: true,
			windowTsLoading:true,
			windowTimestamps: [],
			OperationalParametersParams:payload
		};
	case 'FETCH_WINDOW_TIMESTAMP_SUCCEEDED':
		return {
			...state,
			loading: false,
			windowTsLoading:false,
			windowTimestamps: payload
		};
	case 'FETCH_WINDOW_TIMESTAMP_FAILED':
		return {
			...state,
			windowTsLoading:false,
			loading: false
		};
	case 'TOGGLE_FEATURE':
		return {
			...state,
			featureEnabled: !state.featureEnabled
		};
	case 'GET_ADMIN_COMPANY_REQUESTED':
		return {
			...state,
			loading: true,
			adminCompany: []
		};
	case 'GET_ADMIN_COMPANY_SUCCEEDED':
		return {
			...state,
			loading: false,
			adminCompany: payload
		};
	case 'GET_ADMIN_COMPANY_FAILED':
		return {
			...state,
			loading: false
		};
	case 'GET_FACTORY_PRODUCT_BY_ID_REQUESTED':
		return {
			...state,
			loading: true,
			factoryProduct: []
		};
	case 'GET_FACTORY_PRODUCT_BY_ID_SUCCEEDED':
		return {
			...state,
			loading: false,
			factoryProduct: payload
		};
	case 'GET_FACTORY_PRODUCT_BY_ID_FAILED':
		return {
			...state,
			loading: false
		};
	case 'GET_ENTERPRISE_PRODUCT_BY_ID_REQUESTED':
		return {
			...state,
			loading: true,
			enterpriseProduct: []
		};
	case 'GET_ENTERPRISE_PRODUCT_BY_ID_SUCCEEDED':
		return {
			...state,
			loading: false,
			enterpriseProduct: payload
		};
	case 'GET_ENTERPRISE_PRODUCT_BY_ID_FAILED':
		return {
			...state,
			loading: false
		};
	case 'GET_PRODUCTION_VOLUME_DETAILS_REQUESTED':
		return {
			...state,
			loading: true,
			productionVolume: null
		};
	case 'GET_PRODUCTION_VOLUME_DETAILS_SUCCEEDED':
		return {
			...state,
			loading: false,
			productionVolume: payload
		};
	case 'GET_PRODUCTION_VOLUME_DETAILS_FAILED':
		return {
			...state,
			loading: false
		};
	case 'GET_PRODUCTION_VOLUME_BY_FACTORIES_REQUESTED':
		return {
			...state,
			loading: true,
			productionVolumeByFactories: null
		};
	case 'GET_PRODUCTION_VOLUME_BY_FACTORIES_SUCCEEDED':
		return {
			...state,
			loading: false,
			productionVolumeByFactories: payload
		};
	case 'GET_PRODUCTION_VOLUME_BY_FACTORIES_FAILED':
		return {
			...state,
			loading: false
		};	
	case 'GET_PRODUCTION_VOLUME_BY_LINES_REQUESTED':
		return {
			...state,
			loading: true,
			productionVolumeByLines: null
		};
	case 'GET_PRODUCTION_VOLUME_BY_LINES_SUCCEEDED':
		return {
			...state,
			loading: false,
			productionVolumeByLines: payload
		};
	case 'GET_PRODUCTION_VOLUME_BY_LINES_FAILED':
		return {
			...state,
			loading: false
		};
	case 'UPDATE_VERTICAL_NAVIGATION':
		return {
			...state,
			verticalNavigation: payload
		};
	default:
		return {
			...state
		};
	}
};

export default dashboardReducer;
