import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HvDropdown, HvGrid, HvTypography } from '@hitachivantara/uikit-react-core';
import PropTypes from 'prop-types';
import { getSliceConfigurationParameters, getMachineAvailabilityInsights, getReasonCodesApi, getDowntimeStatusApi } from '../actions';
import { ERROR_MESSAGE, isEmpty } from '../shared/components/Util';
import TimeScaleChart from '../shared/components/Charts/TimeScaleChart';
import MultiColorBarChart from '../shared/components/Charts/MultiColorBarChart';
import DatePicker from '../shared/components/DatePicker/DatePicker';
import { Add, Edit } from '@hitachivantara/uikit-react-icons';
import DailogBox from '../shared/components/DailogBox';
import Loading from '../shared/Loading/Loading';
import { useLocation } from 'react-router';


function MachineAvailability({ maData,uid }) {
	MachineAvailability.propTypes = {
		maData: PropTypes.object.isRequired,
		uid: PropTypes.string.isRequired
	};
	
	const tempDropdownList = [{label: 'Normal' },{ label: 'Insight', selected: true}];
	const dropdownList = [{label: 'Normal' },{ label: 'Insight' }];
	let [selectedView, setSelectedView] = useState('Insight');
	let [maViewList, setMaViewList] = useState(tempDropdownList);
	const dispatch = useDispatch();
	const [edit, setEdit] = useState(true);
	const downtimeReasons = useSelector((state) => state.machine.reasonCodes);
	const downtimeStatusCodes = useSelector((state) => state.machine.downtimeStatus);
	const windowTimestamps = useSelector((state) => state.dashboard.windowTimestamps);
	const featureEnabled = useSelector((state) => state.dashboard.featureEnabled);
	const operationalParametersParams = useSelector((state) => state.dashboard.OperationalParametersParams);
	const sliceCfgData = useSelector((state) => state.machine.sliceConfigurationParameters);
	const maInsigts = useSelector((state) => state.machine.machineAvailabilityInsights);
	const loading = useSelector((state) => state.machine.loading);
	const location = useLocation();
	const [errorMsg, setErrorMsg] = useState('');
	const [toggledOffData, setToggledOffData] = useState([]);

	const handleOptionClickToggledOff = (params) => {
		setToggledOffData({ ...params });
	};

	const dispatchToGetSliceConfigurationParameters = (windowTimestamps,params) => {
		dispatch(
			getSliceConfigurationParameters({
				sliceId: params.sliceId,
				thingId: location.state?.hierarchyDetails?.thingId,
				endTimestamp: windowTimestamps[0].end_datetime_ep,
				startTimestamp: windowTimestamps[0].start_datetime_ep,
				uid: uid//params.uid
			})
		);
	};

	const dispatchToGetMachineAvailabilityInsights = (windowTimestamps, opPrms) => {
		dispatch(
			getMachineAvailabilityInsights({
				thingId: location.state?.hierarchyDetails?.thingId,
				filter_datetime: windowTimestamps[0].end_datetime_ep,
				mode: opPrms.mode,
				hour_mode_duration: opPrms.hour_mode_duration,
				filter_start:windowTimestamps[0].start_datetime_ep,
				filter_end: windowTimestamps[0].end_datetime_ep
			})
		);
	};
	
	const dispatchToGetDowntimeReasonCodes = (params) => {
		dispatch(
			getReasonCodesApi({
				uid: params.uid,
				thingId:location.state?.hierarchyDetails?.thingId
			})
		);
	};

	const dispatchToGetDowntimeSatus = (windowTimestamps) => {
		dispatch(
			getDowntimeStatusApi({
				thing_id: windowTimestamps[0].thing_id
			})
		);
	};
	
	useEffect(() => {
		if(!isEmpty(windowTimestamps) && windowTimestamps.length > 0 && !isEmpty(operationalParametersParams) && edit!=='add' && edit != 'edit' && edit && isEmpty(toggledOffData)) {
			dispatchToGetMachineAvailabilityInsights(windowTimestamps, operationalParametersParams);
			dispatchToGetSliceConfigurationParameters(windowTimestamps, maData);
			dispatchToGetDowntimeSatus(windowTimestamps);
			dispatchToGetDowntimeReasonCodes(maData);
		} else if (!isEmpty(toggledOffData) && !isEmpty(toggledOffData.wts)) {
			dispatchToGetMachineAvailabilityInsights(toggledOffData.wts, toggledOffData);
			dispatchToGetSliceConfigurationParameters(toggledOffData.wts, maData);
			dispatchToGetDowntimeSatus(toggledOffData.wts);
			dispatchToGetDowntimeReasonCodes(maData);
		} else if(isEmpty(windowTimestamps) && isEmpty(operationalParametersParams) && edit!=='add' && edit != 'edit'){
			setErrorMsg('error in app');
		}
	}, [windowTimestamps, location, toggledOffData, edit]);
	
	const selectView = (item) => {
		if(item===undefined){
			item={label: selectedView};
		}
		let updatedList=dropdownList.map((items) => {
			if(items.label === item.label){
				return { label: items.label, selected: true};
			}else{return items;}
		});
		setMaViewList(updatedList);
		setSelectedView(item.label);
	};
	const onOpen = (k) => {
		setEdit(k);
	};
	
	let sortedReason = [];
	if (!isEmpty(downtimeReasons)&& !isEmpty(downtimeReasons.content)) {
		const dropdownReason = [];
		downtimeReasons.content.map((items) => {
			dropdownReason.push({ id: items.id, label: items.description });
		});
		sortedReason = dropdownReason.sort((a, b) => {
			return a.id - b.id;
		});
	}

	let sortedStatus = [];
	if(!isEmpty(downtimeStatusCodes)) {
		const dropdownStatus = [];
		downtimeStatusCodes.map((items) => {
			dropdownStatus.push({ id: items.Order, label: items.Label });
		});
		sortedStatus = dropdownStatus.sort((a, b) => {
			return a.id - b.id;
		});
	}
	let unPlannedData = [];
	if (!isEmpty(sliceCfgData)) {
		unPlannedData = [];
		let data=null;
		try {
			data = JSON.parse(sliceCfgData);
			if(data!==undefined && data.data!==undefined ){
				data.data.map((item) => {
					if (item.status === 'Running') {
					//skip it
					}else{
						unPlannedData.push(item);
					}
				});
			}
		} catch (error) {
			//console.error('Exception in sliceCfgData & parsing==',error);
		}
	}

	return (
		<>
			<HvGrid container>
				<HvGrid item md={12}></HvGrid>
				<HvGrid item md={12} style={{paddingTop:'15px'}}>
					<HvTypography variant="title4">
						<HvGrid container>
							<HvGrid item md={9} style={{display:'flex'}}>
									Machine Availability
								<HvDropdown
									aria-label="Single selection"
									id="views"
									style={{ width: '200px', marginLeft:'10px' }}
									onClick={() => {}}
									onChange={selectView}
									values={maViewList}
								/>
							</HvGrid>
							<HvGrid item md={3} style={{display:'flex'}}>
								{!isEmpty(unPlannedData) && (<Edit style={{ cursor: 'pointer',marginTop:'15px' }} onClick={() => onOpen('edit')} />)}
								<Add style={{ cursor: 'pointer',marginTop:'15px' }} onClick={() => onOpen('add')} />
								<DatePicker featureEnabled={featureEnabled} uid={uid} thingId={location?.state?.hierarchyDetails?.thingId} handleOptionClickToggledOff={handleOptionClickToggledOff}/> 
							</HvGrid>
						</HvGrid>
					</HvTypography>
				</HvGrid>
				<HvGrid item md={12}>
					{loading && <Loading />}
					{!loading &&selectedView === 'Insight' && !isEmpty(maInsigts) &&  (
						<MultiColorBarChart insigtsData={maInsigts[0]}/>
					)}
					{selectedView === 'Insight' && !loading && isEmpty(maInsigts[0]) && isEmpty(errorMsg) && (
						<HvGrid container style={{margin:'30px 0px 60px 0px'}}>
							<HvGrid item md={4}>
							</HvGrid>
							<HvGrid item md={8}>
								<h1>No Data Found</h1>
							</HvGrid>
						</HvGrid>
					)}
					{selectedView === 'Insight' && !loading && isEmpty(maInsigts[0]) && !isEmpty(errorMsg) && (
						<HvGrid container style={{margin:'30px 0px 60px 0px'}}>
							<HvGrid item md={4}>
							</HvGrid>
							<HvGrid item md={8}>
								<h1 style={{color:'red'}}>{ERROR_MESSAGE}</h1>
							</HvGrid>
						</HvGrid>
					)}
					{!loading && selectedView === 'Normal' && !isEmpty(sliceCfgData) && (
						<TimeScaleChart sliceCfgData={sliceCfgData} mode={operationalParametersParams.mode}/>
					)}
					{selectedView === 'Normal' && !loading && isEmpty(sliceCfgData) && isEmpty(errorMsg) && (
						<HvGrid container style={{margin:'30px 0px 60px 0px'}}>
							<HvGrid item md={4}>
							</HvGrid>
							<HvGrid item md={8}>
								<h1>No Data Found</h1>
							</HvGrid>
						</HvGrid>
					)}
					{selectedView === 'Normal' && !loading && isEmpty(sliceCfgData) && !isEmpty(errorMsg) &&  (
						<HvGrid container style={{margin:'30px 0px 60px 0px'}}>
							<HvGrid item md={4}>
							</HvGrid>
							<HvGrid item md={8}>
								<h1 style={{color:'red'}}>{ERROR_MESSAGE}</h1>
							</HvGrid>
						</HvGrid>
					)}
				</HvGrid>
			</HvGrid>
				
			{edit === 'edit' && !isEmpty(sliceCfgData)  && ( 
				<DailogBox parentCallback={setEdit}	maData={maData} thingId={location.state?.hierarchyDetails?.thingId} insigtsData={maInsigts} sliceCfgData={sliceCfgData} editAdd="edit" statusCodes={sortedStatus}
					reasonCodes={sortedReason}/>)}
			{edit === 'add' && (
				<DailogBox parentCallback={setEdit} maData={maData} thingId={location.state?.hierarchyDetails?.thingId} insigtsData={maInsigts} sliceCfgData={sliceCfgData} editAdd="add" statusCodes={sortedStatus}
					reasonCodes={sortedReason}/>)}
		</>
	);
}
export default MachineAvailability;
