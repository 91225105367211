import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import Loading from '../../Loading/Loading';

const LineChartComponent = ({ data, CustomXAxisTick, loading, currentTab }) => {
	LineChartComponent.propTypes = {
		data: PropTypes.array.isRequired,
		CustomXAxisTick: PropTypes.array.isRequired,
		loading: PropTypes.bool.isRequired,
		currentTab: PropTypes.number.isRequired
	};

	const hasDefaultData = data.every((item) => item.sensorTimestamp === 0 || item.value === 0 || item.value === null);

	const firstTimestamp = CustomXAxisTick[0];
	const lastTimestamp = CustomXAxisTick[CustomXAxisTick.length - 1];
	const extractedData = [];

	const dataInterval = () => {
		if (currentTab===0 || currentTab===1) {
			return 15 * 1000;
		} else if (currentTab===2 || currentTab===3) {
			return 60 * 1000;
		} else if (currentTab===4) {
			return 10 * 60 * 1000;
		} 
	};

	for (let i = firstTimestamp; i <= lastTimestamp; i += dataInterval()) {
		const timestamp = i;
		const entry = data.find((item) => item.sensorTimestamp === i);
		if (entry) {
			extractedData.push(entry);
		} else {
			const dummyData = {
				'sensor_date': new Date(timestamp).toISOString(),
				'sensorTimestamp': i,
				'value': '0',
				'avg_value': data[0]?.avg_value,
				'DeviceId': data[0]?.DeviceId,
				'SiteWiseAssetId': '',
				'KPI_Label': data[0]?.KPI_Label,
				'Lower_Critical_Threshold': data[0]?.Lower_Critical_Threshold,
				'Upper_Critical_Threshold': data[0]?.Upper_Critical_Threshold,
				'Lower_Warning_Threshold': data[0]?.Lower_Warning_Threshold,
				'Upper_Warning_Threshold': data[0]?.Upper_Warning_Threshold,
				'Unit_of_Measure': data[0]?.Unit_of_Measure,
				'Enable_KPI_monitoring': '',
				'Roundoff_Factor': ''
			};
			extractedData.push(dummyData);
		}
	}

	const yValues = extractedData.map((item) => Number(item.value));
	const max = Math.max(...yValues);

	const [chartVisibility, setChartVisibility] = useState({
		'KPI Value': true,
		'Upper Critical Threshold': true,
		'Lower Critical Threshold': true,
		'Upper Warning Threshold': true,
		'Lower Warning Threshold': true,
		Average: true
	});

	const toggleChartVisibility = (key) => {
		setChartVisibility((prevState) => ({
			...prevState,
			[key]: !prevState[key]
		}));
	};

	const CustomLegend = () => {
		const legendItems = [
			{
				label: 'KPI Value',
				color: '#58C9DD'
			},
			{
				label: 'Upper Critical Threshold',
				color: '#ED6868'
			},
			{
				label: 'Lower Critical Threshold',
				color: '#ED6864'
			},
			{
				label: 'Upper Warning Threshold',
				color: '#E68C17'
			},
			{
				label: 'Lower Warning Threshold',
				color: '#E68C17'
			},
			{
				label: 'Average',
				color: '#FFDB70'
			}
		];

		return (
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
				{legendItems.map((item) => (
					<div
						key={item.label}
						style={{
							cursor: 'pointer',
							marginRight: '20px',
							display: 'flex',
							alignItems: 'center',
							textDecoration: chartVisibility[item.label]
								? 'none'
								: 'line-through'
						}}
						onClick={() => toggleChartVisibility(item.label)}
					>
						<div
							style={{
								width: '10px',
								height: '6px',
								background: item.color,
								marginRight: '5px'
							}}
						/>
						{item.label}
					</div>
				))}
			</div>
		);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			const formatDate = (timestamp) => {
				const date = new Date(timestamp);
				const formattedDate = date.toLocaleDateString('en-US', {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					second: 'numeric'
				});
				return formattedDate;
			};

			return (
				<div style={{ background: 'white', border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
					<div style={{ color: 'black', marginBottom: '5px' }}>
						Time: {formatDate(label)}
					</div>
					{payload.map((dataItem, index) => (
						<div key={`tooltip-item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
							<div style={{ width: '20px', height: '6px', background: dataItem.color, marginRight: '5px' }}></div>
							<div style={{ color: 'black' }}>
								{dataItem.name}: {dataItem.value || 0} {dataItem.payload.Unit_of_Measure}
							</div>
						</div>
					))}
				</div>
			);
		}

		return null;
	};

	CustomTooltip.propTypes = {
		active: PropTypes.bool,
		payload: PropTypes.arrayOf(
			PropTypes.shape({
				color: PropTypes.string,
				value: PropTypes.string,
				name: PropTypes.string
			})
		),
		label: PropTypes.number
	};

	const isAllLegendsHidden = () => {
		return Object.keys(chartVisibility).filter((key) => key !== 'sensor_date').every((key) => !chartVisibility[key]);
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			{loading || data.length === 0 ? (
				<Loading />
			) : hasDefaultData ? (
				<div>
					<p>No Data Found</p>
				</div>
			) : (
				<>
					<ResponsiveContainer width="100%" height={400}>
						<LineChart data={extractedData} margin={{ right: 30, bottom: 20 }}>
							<CartesianGrid strokeDasharray="3 3" />
							{isAllLegendsHidden() ? null : (
								<XAxis
									dataKey="sensorTimestamp"
									type="category"
									allowDuplicatedCategory={false}
									ticks={CustomXAxisTick}
									interval={0}
									tickFormatter={(timestamp, index) => {
										const date = new Date(timestamp);
										const hours = date.getHours();
										const minutes = date.getMinutes();
										const ampm = hours >= 12 ? 'PM' : 'AM';
										const formattedHours = hours % 12 || 12;
										const formattedMinutes = minutes.toString().padStart(2, '0');
										if (index == 0) {
											const curTimestamp = CustomXAxisTick[index + 1];
											const timeDiff = curTimestamp - timestamp;
											if (timeDiff >= 23 * 3600 * 1000 && timeDiff <= 25 * 3600 * 1000) {
												const months = [
													'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
												];
												const month = months[date.getMonth()];
												const day = date.getDate();
												return `${month}-${day}`;
											}
										}else if (index > 0) {
											const prevTimestamp = CustomXAxisTick[index - 1];
											const timeDiff = timestamp - prevTimestamp;
											if (timeDiff >= 23 * 3600 * 1000 && timeDiff <= 25 * 3600 * 1000) {
												const months = [
													'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
												];
												const month = months[date.getMonth()];
												const day = date.getDate();
												return `${month}-${day}`;
											}
										}
										return `${formattedHours}:${formattedMinutes} ${ampm}`;
									}}
									tick={{ fontSize: 14 }}
									tickMargin={25} 
								/>
							)}
							<YAxis
								label={{
									value: data[0]?.Unit_of_Measure,
									angle: -90,
									position: 'insideLeft',
									fontSize: 14
								}}
								domain={[0, max]} 
								tick={{ fontSize: 14 }}
								height={20}
								tickMargin={10} 
							/>
							<Tooltip content={<CustomTooltip />} />
							<Line
								connectNulls
								type="monotone"
								dataKey="value"
								stroke="#58C9DD"
								name="KPI Value"
								isAnimationActive={false}
								hide={!chartVisibility['KPI Value']}
								dot={false}
							/>
							<Line
								connectNulls
								type="monotone"
								dataKey="Upper_Critical_Threshold"
								stroke="#ED6868"
								name="Upper Critical Threshold"
								isAnimationActive={false}
								hide={!chartVisibility['Upper Critical Threshold']}
								dot={false}
							/>
							<Line
								connectNulls
								type="monotone"
								dataKey="Lower_Critical_Threshold"
								stroke="#ED6864"
								name="Lower Critical Threshold"
								isAnimationActive={false}
								hide={!chartVisibility['Lower Critical Threshold']}
								dot={false}
							/>
							<Line
								connectNulls
								type="monotone"
								dataKey="Upper_Warning_Threshold"
								stroke="#E68C17"
								name="Upper Warning Threshold"
								isAnimationActive={false}
								hide={!chartVisibility['Upper Warning Threshold']}
								dot={false}
							/>
							<Line
								connectNulls
								type="monotone"
								dataKey="Lower_Warning_Threshold"
								stroke="#E68C17"
								name="Lower Warning Threshold"
								isAnimationActive={false}
								hide={!chartVisibility['Lower Warning Threshold']}
								dot={false}
							/>
							<Line
								connectNulls
								type="monotone"
								dataKey="avg_value"
								stroke="#FFDB70"
								name="Average"
								isAnimationActive={false}
								hide={!chartVisibility['Average']}
								dot={false}
							/>
						</LineChart>
					</ResponsiveContainer>
					<CustomLegend />
				</>
			)}
		</div>
	);
};
export default LineChartComponent;

