const initialState = {
	loadingTrending: false,
	loadingDefect: false,
	qualityKpiData: [],
	qualityDefectData: [],
	qualityProdDropdown: []
};

const qualityAnalyticsReducer = (state = initialState, { type, payload }) => {
	switch (type) {
	case 'FETCH_QUALITY_KPI_REQUESTED':
		return {
			...state,
			loadingTrending: true,
			qualityKpiData: []
		};
	case 'FETCH_QUALITY_KPI_SUCCEEDED':
		return {
			...state,
			loadingTrending: false,
			qualityKpiData: payload
		};
	case 'FETCH_QUALITY_KPI_FAILED':
		return {
			...state,
			loadingTrending: false
		};
	case 'FETCH_QUALITY_DEFECT_REQUESTED':
		return {
			...state,
			loadingDefect: true,
			qualityDefectData: []
		};
	case 'FETCH_QUALITY_DEFECT_SUCCEEDED':
		return {
			...state,
			loadingDefect: false,
			qualityDefectData: payload
		};
	case 'FETCH_QUALITY_DEFECT_FAILED':
		return {
			...state,
			loadingDefect: false
		};
	case 'FETCH_QUALITY_PROD_DROPDOWN_REQUESTED':
		return {
			...state,
			loading: true,
			qualityProdDropdown: []
		};
	case 'FETCH_QUALITY_PROD_DROPDOWN_SUCCEEDED':
		return {
			...state,
			loading: false,
			qualityProdDropdown: payload
		};
	case 'FETCH_QUALITY_PROD_DROPDOWN_FAILED':
		return {
			...state,
			loading: false
		};	
	default:
		return {
			...state
		};
	}
};

export default qualityAnalyticsReducer;
