import React from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { HvBox } from '@hitachivantara/uikit-react-core';
import './Charts.css';
import PopupBarChart from './PopupBarChart';
import PropTypes from 'prop-types';
import warning from '../../../assets/images/Warning.svg';
import normal from '../../../assets/images/Healthy_icon.svg';
import critical from '../../../assets/images/critical.svg';
import { ERROR_MESSAGE, checkBarStatus, isEmptyReturnZero, isFloat } from '../Util';
import { MoreOptionsHorizontal, Stable } from '@hitachivantara/uikit-react-icons';
import Loading from '../../Loading/Loading';

function BarChart({ data, loading,errorMsg }) {
	BarChart.propTypes = {
		data: PropTypes.array.isRequired,
		loading: PropTypes.bool.isRequired,
		errorMsg:PropTypes.any
	};
	ChartJS.register([annotationPlugin]);
	let sensorData = [];
	let componentData = [];
	let subSysData = [];

	if (!isEmpty(data)) {
		sensorData = [];
		componentData = [];
		subSysData = [];
		data.map((item) => {
			if (item.parent_thing_type === 'Machine') {
				if (item.thing_type === 'Sensor') {
					sensorData.push(item);
				}
				if (item.thing_type === 'Component') {
					componentData.push(item);
				}
				if (item.thing_type === 'Sub System') {
					subSysData.push(item);
				}
			}
		});
	}
	let footerList = sensorData.map((item, index) => {
		return (
			<span key={index} id={item.kpi_label} className="dataLables" style={{fontSize:'13px'}}>
				{item.kpi_label?.length >= 5 ? item.kpi_label?.slice(0, 4) : item.kpi_label}
			</span>
		);
	});
	let headerList = sensorData.map((item, index) => {
		if(isFloat(isEmptyReturnZero(item.value))){
			return (
				<span key={index} id={item.value} className="dataLables">
					{parseFloat(isEmptyReturnZero(item.value)).toFixed(1)}{item.unit_of_measure}
				</span>
			);
		}else{
			return (
				<span key={index} id={item.value} className="dataLables">
					{isEmptyReturnZero(item.value)}{item.unit_of_measure}
				</span>
			);
		}
		
	});

	

	let barList = sensorData.map((item, index) => {
		return (
			<PopupBarChart
				key={index}
				toolTipData={item}
				logo={getLogo(item)}
				color={checkBarStatus(item)}></PopupBarChart>
		);
	});

	return (
		<>
			<div className='util-chart'>
				{!isEmpty(data) && !isEmpty(sensorData) && (
					<div className="hedding">
						<div style={{display:'flex',fontWeight:'bold'}}><Stable style={{height:'0px'}}/> Critical <MoreOptionsHorizontal style={{height:'0px'}}/> Warning</div>
					</div>
				)}
				{loading && <Loading />}
				{!isEmpty(data) && !isEmpty(sensorData) && !loading && (
					<div style={{ paddingTop:'4px', overflow:'auto'}}>
						<HvBox
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								padding: '0px 0px 0px 0px'
							}}>
							{headerList}
						</HvBox>
						<HvBox
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								padding: '0px 0px 0px 0px'
							}}>
							{barList}
						</HvBox>
						<HvBox
							sx={{
								display: 'flex',
								justifyContent: 'space-evenly',
								padding: '0px 0px 0px 0px'
							}}>
							{footerList}
						</HvBox>
					</div>
				)}
				{!loading && isEmpty(data) && isEmpty(sensorData) && isEmpty(errorMsg) && <div style={{ marginTop: '125px', fontSize:'14px', display: 'flex', justifyContent: 'center'}}> <h1> No Data Found</h1></div>}
				{!loading && isEmpty(data) && isEmpty(sensorData) && !isEmpty(errorMsg) && <div style={{marginTop: '125px', display: 'flex', justifyContent: 'center', alignItems: 'center', color:'red'}}> <h1>{ERROR_MESSAGE}</h1></div>}
			</div>
		</>
	);
}

function isEmpty(value) {
	let flag = false;
	if (value == null || value == undefined) {
		flag = true;
	} else if (value != null && value != undefined) {
		if (value != '') {
			if (value.length != 0) {
				flag = false;
			} else {
				flag = true;
			}
		} else {
			flag = true;
		}
	}
	return flag;
}

function getLogo(item) {
	if (item.value <= item.lower_critical_threshold || item.value >= item.upper_critical_threshold) {
		return critical; //'critical';
	} else if (
		item.value <= item.lower_warning_threshold &&
    item.value > item.lower_critical_threshold
	) {
		return warning; //'warning';
	} else if (
		item.value >= item.upper_warning_threshold &&
    item.value < item.upper_critical_threshold
	) {
		return warning; //'warning';
	} else {
		return normal; //'normal';
	}
}

export default BarChart;