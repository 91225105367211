// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KPIcard {
  width: 210px;
  border: 1.5px solid #adb5bd;
  border-top: 3px solid rgb(255, 82, 82);
  border-radius: 6px;
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 95px;
}
  
.KPIcard-header {
  font-size: 1.5em;
  color: black;
  padding-bottom: 8px;
  font-weight: bold;
}
  
.KPIcard-content {
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.value {
  font-size: 2.5em;
}

.unit {
  font-size: 1.5em;
  padding-top: 8px;
}
  
@media screen and (min-width: 1270px) {
  #MTBF {
    padding-left : 8%;
  }

  #MTTR {
    padding-left : 10%;
  }
}

@media screen and (min-width: 1920px){
  #MTBF {
    padding-left : 5%;
    max-width: 40%;
  }
  
  #MTTR {
    padding-left : 5%;
    max-width: 40%;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/KpiCard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,sCAAsC;EACtC,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;AACF","sourcesContent":[".KPIcard {\r\n  width: 210px;\r\n  border: 1.5px solid #adb5bd;\r\n  border-top: 3px solid rgb(255, 82, 82);\r\n  border-radius: 6px;\r\n  background-color: white;\r\n  padding: 16px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 95px;\r\n}\r\n  \r\n.KPIcard-header {\r\n  font-size: 1.5em;\r\n  color: black;\r\n  padding-bottom: 8px;\r\n  font-weight: bold;\r\n}\r\n  \r\n.KPIcard-content {\r\n  display: flex;\r\n  align-items: center;\r\n  padding-left: 25px;\r\n}\r\n\r\n.value {\r\n  font-size: 2.5em;\r\n}\r\n\r\n.unit {\r\n  font-size: 1.5em;\r\n  padding-top: 8px;\r\n}\r\n  \r\n@media screen and (min-width: 1270px) {\r\n  #MTBF {\r\n    padding-left : 8%;\r\n  }\r\n\r\n  #MTTR {\r\n    padding-left : 10%;\r\n  }\r\n}\r\n\r\n@media screen and (min-width: 1920px){\r\n  #MTBF {\r\n    padding-left : 5%;\r\n    max-width: 40%;\r\n  }\r\n  \r\n  #MTTR {\r\n    padding-left : 5%;\r\n    max-width: 40%;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
