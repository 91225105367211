import React from 'react';
import { HvGrid, HvTypography } from '@hitachivantara/uikit-react-core';
import SimpleTable from '../shared/components/Table/SimpleTable';
import PropTypes from 'prop-types';

function AssetParameters({loading}) {
	AssetParameters.propTypes = {
		loading:PropTypes.bool.isRequired
	};

	const columns = [
		{
			Header: 'Sensor',
			accessor: 'sensor'
		},
		{
			Header: 'Critical',
			accessor: 'critical',
			valueType:'percetage'
		},
		{
			Header: 'Warning',
			accessor: 'warning',
			valueType:'percetage'
		},
		{
			Header: 'Healthy',
			accessor: 'healthy',
			valueType:'percetage'
		}
	];

	const data = [
		{
			sensor:'Moter Speed (RPM)',
			critical: '5',
			warning:'35',
			healthy:'60'
		},
		{
			sensor:'DE Bearing Temperature (C) ',
			critical: '55',
			warning:'25',
			healthy:'20'
		},
		{
			sensor:'Diff Pressure (PSI)',
			critical: '51',
			warning:'29',
			healthy:'20'
		},
		{
			sensor:'Motor Current (Amps)',
			critical: '5',
			warning:'60',
			healthy:'35'
		},
		{
			sensor:'Motor Voltage (V)',
			critical: '2',
			warning:'3',
			healthy:'95'
		}
	];

	return (
		<HvGrid container direction="column"  >
			<HvGrid item>
				<HvTypography variant="title3">
					<div style={{ display: 'flex' }}>
						<div>Asset Parameters
							<div style={{ display: 'flex', fontSize: '11px', marginTop: '10px', width: '220px',gap:25 }}>
								<div style={{ display: 'flex' }}><div style={{ backgroundColor: 'rgb(255, 82, 82)', marginRight: '8px', width: '12px', height: '12px' }}></div><div style={{ lineHeight: 1.1,fontSize:12,fontWeight:'bold'}}>Critical</div></div>
								<div style={{ display: 'flex' }}><div style={{ backgroundColor: 'rgb(231, 120, 77)', marginRight: '8px', width: '12px', height: '12px' }}></div><div style={{ lineHeight: 1.1,fontSize:12,fontWeight:'bold'}}>Warning</div></div>
								<div style={{ display: 'flex' }}><div style={{ backgroundColor: 'rgb(99, 166, 33)', marginRight: '8px', width: '12px', height: '12px' }}></div><div style={{ lineHeight: 1.1,fontSize:12,fontWeight:'bold'}}>Healthy</div></div>
							</div>
						</div>
					</div>

					<div style={{ marginTop:'5px'}} >
						{data && <SimpleTable columns={columns} data={data} loading={loading} /> }
					</div>
				</HvTypography>
			</HvGrid>
		</HvGrid>
	);
}

export default AssetParameters;